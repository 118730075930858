import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import LangContext from "modules/shared/context/langContext";
import { Link } from "react-router-dom";
import {
  Menu,
  Dropdown,
  TreeSelect,
  Button,
  Row,
  Col,
  Icon,
  Badge,
  Spin,
  Tooltip,
  message,
  Radio,
  Switch,
  Input
} from "antd";
import { renderToString } from "react-dom/server";
import supercluster from "points-cluster";
import { TrackingStyledContent } from "../../../../styled/common-styled";
import {
  loadTrackingInitData,
  loadTracking,
  loadVehicleTypeMasterData,
  loadVehicleGroupMasterData,
  loadListVehicleMdvr,
  loadVehicle,
  removeLists,
  loadListVehicleHoneytoast,
} from "../../actions";
import { debounce } from "debounce";
import {
  changeConfigTrackingColumns,
  changeConfigTrackingTableCarRegisColumns,
  changeConfigTrackingTableDriverColumns,
} from "../../../auth/actions";

import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import {
  checkInsidePolygon,
  getDistanceFromPointToPoint,
  calcCrowDistance,
  computeArea,
  handleFilter,
} from "lib/helper";
import {
  SELECTED_VEHICLE_OBJ,
  COLUMNS as allTrackingColumns,
  DEFAULT_FILTER_VALUE,
} from "../constants/tracking";

import LocationTypeDropdown from "./LocationTypeDropdown";
import { FilterModal } from "./FilterModal";
import { ConfigModal } from "./ConfigModal";
import { CustomTable } from "./CustomTable";
import { CustomTableTrackingCarRegis } from "./CustomTableTrackingCarRegis";
import { GoogleMap } from "./GoogleMap";
import { HereMap } from "./HereMap";
import { TrackingPinPoint } from "./TrackingPinPoint";
import { Popup } from "./Popup";
import VehicleMarker from "../../../shared/components/map-assets/Marker/VehicleMarker";
import PlaceMarker from "../../../shared/components/map-assets/Marker/PlaceMarker";
import ClusterMarker from "../../../shared/components/map-assets/ClusterMarker";
// import { messaging } from "../../../../lib/fcm/fcm_init";
import Notification from "./Notification";
import axios from "axios";
import moment from "moment";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import IconMarker from "../../../shared/components/map-assets/Marker/IconMarker";
import IconWithShowTootip from "../../../shared/components/map-assets/Marker/IconWithShowTootip";
import UniversalSearch from "./UniversalSearch";
import { PopupDrawerNew } from "./PopupDrawerNew";
import Iframe from "react-iframe";
import { async } from "rxjs";
import MarkerTitleDisplay from "./MarkerTitleDisplay";

const mapMinimumZoomToShowGroupByLocation = 10;
const ButtonGroup = Button.Group;

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const initSelectedVehicle = SELECTED_VEHICLE_OBJ;
const { Search } = Input;
export class Index extends Component {
  state = {
    curTime: null,
    time: "",
  };

  constructor(props) {
    super(props);
    this.searchRef = createRef();
    this.defaultMapValue = {
      center: {
        lat: 13.729852,
        lng: 100.559484,
      },
      zoom: 5,
    };

    this.state = {
      showEditTable: true,
      eleSplitterTableLayout: true,
      loadingMdvr: false,
      btnAllCar: true,
      tablePanelClose: false,
      pinLat: null,
      pinLng: null,
      distance: null,
      intervalHours: null,
      intervalMins: null,
      routePolyline: null,
      mapOptions: this.defaultMapValue,
      panelWidth: 575,
      dataDragColumn: null,
      latest_config_change: new Date().getTime(),
      map: null,
      maps: null,
      mapAPI: {
        trafficLayer: null,
        locationLayer: null,
        locationLinkWithNameLayer: null,
      },
      mapLayer: null,
      mapPlatform: null,
      hereMapDraw: 0,
      showLocationLayer: false,
      showTrafficLayer: false,
      showPOI: false,
      showDistance: false,
      showArea: false,
      width: "0px",
      locationInfoMarkerLists: [],
      locationLoading: false,
      poiMarker: null,
      distanceMarker: [],
      distancePolyline: [],
      distanceLineString: null,
      areaPolyline: [],
      descriptionBoxHeader: "",
      descriptionBoxDetail: "",
      masterDataSelectvalue: DEFAULT_FILTER_VALUE.masterDataSelectvalue,
      hasSearchCriteria: false,
      placeMarker: [],
      selectedVehicle: initSelectedVehicle,
      filterVisible: false,
      configVisible: false,
      groupByLocationVisible: false,
      descriptionBoxDrawerWidth: "0px",
      searchName: DEFAULT_FILTER_VALUE.searchName,
      meterStatus: "-",
      engineStatus: "-",
      emergencyStatus: "-",
      vehicleStatus: DEFAULT_FILTER_VALUE.vehicleStatus,
      vehicle_visibility: "",
      speedRange: DEFAULT_FILTER_VALUE.speedRange,
      timeRange: DEFAULT_FILTER_VALUE.timeRange,
      deviceType: DEFAULT_FILTER_VALUE.deviceType,
      clusters: [],
      vehicle_id_from_menu_vehicle: this.props.match.params.id, // vehicle id from page vehicle
      company_id_from_menu_vehicle:
        this.props.match.params.companyID == undefined
          ? this.props.match.params.companyID
          : parseInt(this.props.match.params.companyID), // company id id from page vehicle
      isGroupByLocationClick: false,
      isShowTrafficClick: false,
      isMenuMapToolsCLick: "",
      isMenuMapTypeClick: "",
      data: [],
      token: "",
      notifications: [],
      widthResize: 0,
      heightRezise: 400,
      isMenuMapTypeClick: "",
      searchSource: "internet",
      searchLocationType: ["Site", "Plant", "Other"],
      visibleDrawer: false,
      setDataTable: [],
      jsessionid: "",
      splitterLayoutHNT: false,
      splitterLayoutCustomTable: false,
      iframeLoading1: true,
      url: process.env.REACT_APP_API_MDVR_TERMINUS,
      vehIdSelect: "",
      deviceTypeList: [],
      showBtnSaveTable: false,
      switchCustomizeTable: false,

      locationTypeList: [],
      filter_location_type: [],
      filter_location_type_indeterminate: false,
      filter_location_type_checkAll: false,
      isFilterLocationTypeClick: false,
      visibleFilterLocationType: false,
      firstSearchLocationType: true,
      showLocationName: false,
      vehiclesInMarker: [],
      singleLoadStatus: true,
      showDisplayTitleFilter: false,
      displayTitleMarker: ["plateNo"],
      displayTitleMarkerCheckList: [
        { key: "p", value: "plateNo", check_value: "plateNo" },
        { key: "v", value: "vehicleCode", check_value: "vehicleCode" },
      ],
    };
    this.goBack = this.goBack.bind(this);
  }

  handleSaveCustomizeTable = () => {
    let newColumnCarPlateNo = [
      {
        Column: [
          {
            width: 40,
            dataIndex: "key",
          },
          {
            dataIndex: "eta_to_dest_distance",
            width: 90,
          },
          {
            dataIndex: "flow",
            width: 90,
          },
          {
            dataIndex: "time_diff",
            width: 90,
          },
          {
            dataIndex: "driver_license_info",
            width: 90,
          },
          {
            width: 190,
            dataIndex: "plate_no",
          },
          {
            width: 80,
            dataIndex: "velocity",
          },
          {
            width: 120,
            dataIndex: "meter_fare",
          },
          {
            width: 100,
            dataIndex: "vehicle_status",
          },
          {
            width: 100,
            dataIndex: "driver_license_swiping_status",
          },
          {
            width: 120,
            dataIndex: "latest_pos_update_at",
          },
          {
            width: 120,
            dataIndex: "gps_active_at",
          },
          {
            width: 120,
            dataIndex: "device_code",
          },
          {
            width: 120,
            dataIndex: "meter_status",
          },
          {
            width: 150,
            dataIndex: "vehicle_code",
          },
          {
            width: 400,
            dataIndex: "current_location",
          },
          {
            width: 70,
            dataIndex: "drum_direction",
          },
          {
            width: 100,
            dataIndex: "drum_status",
          },
          {
            width: 150,
            dataIndex: "is_charging",
          },
          {
            width: 100,
            dataIndex: "battery",
          },
          {
            width: 140,
            dataIndex: "drum_rpm",
          },
          {
            width: 140,
            dataIndex: "fuel_status",
          },
          {
            width: 140,
            dataIndex: "temperature1",
          },

          {
            width: 140,
            dataIndex: "temperature2",
          },
          {
            width: 140,
            dataIndex: "PTO",
          },
          {
            width: 140,
            dataIndex: "door1",
          },
          {
            width: 140,
            dataIndex: "door2",
          },
          {
            width: 140,
            dataIndex: "door3",
          },
          {
            width: 140,
            dataIndex: "boom",
          },
          {
            width: 150,
            dataIndex: "sdcard_available1",
          },
          {
            width: 150,
            dataIndex: "sdcard_available2",
          },
          {
            width: 160,
            dataIndex: "vehicle_tel",
          },
          {
            width: 160,
            dataIndex: "img",
          },
          {
            width: 160,
            dataIndex: "is_sos_should_alert",
          },
          {
            width: 160,
            dataIndex: "gps_image_active_at",
          },
          {
            width: 180,
            dataIndex: "LDT",
          },
          {
            width: 180,
            dataIndex: "company_name",
          },
          {
            width: 200,
            dataIndex: "duration_range",
          },
          {
            width: 160,
            dataIndex: "humidity",
          },
          {
            width: 160,
            dataIndex: "humidity2",
          },
          {
            width: 160,
            dataIndex: "RPM",
          },
          {
            width: 160,
            dataIndex: "OBD",
          },
          {
            width: 160,
            dataIndex: "trip_status",
          },
          {
            width: 160,
            dataIndex: "plant",
          },
          {
            width: 100,
            dataIndex: "alarmMessage",
          },
          {
            width: 150,
            dataIndex: "travelDistance",
          },
          {
            width: 160,
            dataIndex: "passenger_count",
          },
          {
            width: 100,
            dataIndex: "model",
          },
          {
            width: 100,
            dataIndex: "start_trip",
          },
          {
            width: 100,
            dataIndex: "alarm_message",
          },
          {
            width: 100,
            dataIndex: "travel_distance",
          },
          {
            width: 100,
            dataIndex: "color",
          },
          {
            width: 180,
            dataIndex: "lastest_driver",
          },
          {
            width: 100,
            dataIndex: "satellite",
          },
          {
            width: 100,
            dataIndex: "sim",
          },
          {
            width: 180,
            dataIndex: "pw_supply",
          },
          {
            width: 130,
            dataIndex: "video",
          },
          {
            width: 150,
            dataIndex: "engine_vol_status",
          },
        ],
      },
    ];
    let newColumnDriver = [
      {
        Column: [
          {
            dataIndex: "plate_no",
            width: 190,
          },
          {
            dataIndex: "driver_license_info",
            width: 90,
          },
          {
            dataIndex: "driver_license_swiping_status",
            width: 70,
          },
          {
            dataIndex: "velocity",
            width: 70,
          },
          {
            dataIndex: "vehicle_status",
            width: 0,
          },
        ],
      },
    ];
    var resNewColumnDriver = newColumnDriver[0].Column.map(
      (obj) =>
        this.state.newColumn[0].Column.find(
          (o) => o.dataIndex === obj.dataIndex
        ) || obj
    );
    var resNewColumnCarPlateNo = newColumnCarPlateNo[0].Column.map(
      (obj) =>
        this.state.newColumn[0].Column.find(
          (o) => o.dataIndex === obj.dataIndex
        ) || obj
    );
    let self = this;
    this.setState(
      {
        showBtnSaveTable: false,
        switchCustomizeTable: false,
        newColumn: [
          {
            Column: this.state.eleSplitterTableLayout
              ? resNewColumnCarPlateNo
              : resNewColumnDriver,
          },
        ],
      },
      () => {
        {
          this.state.eleSplitterTableLayout
            ? fetch(
              `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateuserconfigtrackingcolumn`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.props.auth.accessToken}`,
                },
                body: JSON.stringify({
                  user_id: this.props.profile.id,
                  columns: JSON.stringify(this.state.dataDragColumn),
                  columns_car_plate_no: JSON.stringify(this.state.newColumn),
                }),
              }
            )
              .then((response) => response.json())
              .then((res) => {
                self.props.changeConfigTrackingTableCarRegisColumns(
                  self.props.profile,
                  this.state.newColumn
                );
              })
            : fetch(
              `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateuserconfigtrackingcolumn`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.props.auth.accessToken}`,
                },
                body: JSON.stringify({
                  user_id: this.props.profile.id,
                  columns: JSON.stringify(this.state.dataDragColumn),
                  columns_driver: JSON.stringify(this.state.newColumn),
                }),
              }
            )
              .then((response) => response.json())
              .then((res) => {
                self.props.changeConfigTrackingTableDriverColumns(
                  self.props.profile,
                  self.state.newColumn
                );
              });
        }
      }
    );
  };

  getDragColumn = (data) => {
    let convertData = data
      .map((ele) => ele.dataIndex)
      .filter((ele) => (ele != "key" && ele != "button" ? true : false));
    this.setState({
      dataDragColumn: convertData,
    });
  };

  onChangeEditCustomizeTable = (checked) => {
    this.setState({
      switchCustomizeTable: checked,
      showBtnSaveTable: false,
    });
  };

  hideSpinner1 = () => {
    this.setState({
      iframeLoading1: false,
    });
  };

  initVehicleTypeMasterDataFilter = (vehicleTypeMasterDataTreeFilter) => ({
    title: (
      'ประเภทพาหนะ'
    ),
    value: "vehicle_type_all",
    key: "vehicle_type_all",
    children: vehicleTypeMasterDataTreeFilter.map((obj) => ({
      title: obj.name,
      key: "VT_" + obj.id,
      value: "vehicle_type_id_" + obj.id,
    })),
  });

  initVehicleGroupMasterDataFilter = (vehicleGroupMasterDataTreeFilter) => ({
    title: (
      'กลุ่มพาหนะ'
    ),
    value: "vehicle_group_all",
    key: "vehicle_group_all",
    children: vehicleGroupMasterDataTreeFilter.map((obj) => ({
      title: obj.name,
      key: "VG_" + obj.id,
      value: "vehicle_group_id_" + obj.id,
    })),
  });

  getClusters = (trackingList) => {
    const clusters = supercluster(trackingList, {
      minZoom: 0,
      maxZoom: 13,
      radius: 60,
    });

    return clusters(this.state.mapOptions);
  };

  handleZoomAndDragAndDrop = (result) => {
    this.setState({
      btnTruckList: result,
    });
  };

  getVehicleFromBound = () => {
    let vehicleIDs = [];
    this.state.clusters.map((item) =>
      item.points.map((x) => vehicleIDs.push(x.vehicle_id))
    );
    this.setState(
      {
        selectDataTable: true,
        btnTruckList: false,
        setDataTable: vehicleIDs.length != 0 ? vehicleIDs : ["empty"],
        btnAllCar: false,
      },
      () => {
        let self = this;
        !this.props.monitoringState.trackingLoading &&
          this.props.loadTrackingInitData({
            companyID: this.props.auth.profile.company_id,
            token: this.props.auth.accessToken,
            selectedTrackingColumns: this.props.auth.profile.config.menu
              .tracking.columns,
            searchName: handleFilter(this.searchRef.current ? this.searchRef.current.input.state.value : ''),
            deviceType: this.state.deviceType,
            vehicleStatus: this.state.vehicleStatus,
            engineStatus: this.state.engineStatus,
            speedRange: this.state.speedRange,
            timeRange: this.state.timeRange,
            dataTreeFilter: this.state.masterDataSelectvalue,
            vehicle_visibility:
              vehicleIDs.length != 0 ? vehicleIDs.toString() : "",
            cb: (markers) => {
              typeof markers != "undefined" && self.reCalMarker(markers);
              self.handleFilterCancel();
            },
            previousState: this.props.monitoringState.trackingList,
            cbFailure: this.alertError,
          });
      }
    );
  };

  handleListMode = () => {
    this.setState(
      {
        selectDataTable: false,
        setDataTable: [],
        btnAllCar: true,
      },
      () => {
        let self = this;
        !this.props.monitoringState.trackingLoading &&
          this.props.loadTrackingInitData({
            companyID: this.props.auth.profile.company_id,
            token: this.props.auth.accessToken,
            selectedTrackingColumns: this.props.auth.profile.config.menu
              .tracking.columns,
            searchName: handleFilter(this.searchRef.current ? this.searchRef.current.input.state.value : ''),
            deviceType: this.state.deviceType,
            vehicleStatus: this.state.vehicleStatus,
            engineStatus: this.state.engineStatus,
            speedRange: this.state.speedRange,
            timeRange: this.state.timeRange,
            dataTreeFilter: this.state.masterDataSelectvalue,
            vehicle_visibility: this.props.auth.profile.vehicle_visibility,
            cb: (markers) => {
              typeof markers != "undefined" && self.reCalMarker(markers);
              self.handleFilterCancel();
            },
            previousState: this.props.monitoringState.trackingList,
            cbFailure: this.alertError,
          });
      }
    );
  };

  createClusters = (trackingList) => {
    let clusters = this.getClusters(trackingList).map((val) => {
      return {
        cluster_lat: val.wy,
        cluster_lng: val.wx,
        numPoints: val.numPoints,
        eleId: `${val.numPoints}_${val.points[0].plate_no}`,
        points: val.points,
        markerInfo: val.points[0],
      };
    });

    this.setState({
      clusters,
    });
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    let self = this;

    if (
      this.state.showLocationLayer &&
      self.state.map.zoom > mapMinimumZoomToShowGroupByLocation
    ) {
      this.generateLocationMarker(self);
    } else if (
      this.state.showLocationLayer &&
      self.state.map.zoom <= mapMinimumZoomToShowGroupByLocation
    ) {
      this.clearLocationMarker(false);
    }

    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
        btnTruckList: true,
      },
      () => {
        this.createClusters(this.props.monitoringState.trackingList);
      }
    );
  };

  onLatestConfigChange = () => {
    this.setState({
      latest_config_change: new Date().getTime(),
    });
  };

  showFilterModal = () => {
    this.setState({
      filterVisible: true,
    });
  };

  handleFilterCancel = () => {
    this.setState({
      filterVisible: false,
    });
  };

  showConfigModal = () => {
    this.setState({
      configVisible: true,
    });
  };

  handleConfigCancel = () => {
    this.setState({
      configVisible: false,
    });
  };

  handleGroupByLocationCancel = () => {
    this.setState({
      groupByLocationVisible: false,
    });
  };

  handleDeviceTypeChange = (value) => {
    this.setState({
      deviceType: value,
      hasSearchCriteria: value.length == 0 ? false : true,
    });
  };

  handleVehicleStatusSelectboxChange = (event) => {
    let obj = {
      vehicleStatus: event,
      hasSearchCriteria: event == "-" ? false : true,
    };
    if (event != "running")
      obj = {
        speedRange: [0, 200],
        vehicleStatus: event,
        hasSearchCriteria: event == "-" ? false : true,
      };
    this.setState(obj);
  };

  handleEngineSelectboxChange = (event) => {
    this.setState({
      engineStatus: event,
      hasSearchCriteria: event == "-" ? false : true,
    });
  };

  handleSpeedRangeChange = (value) => {
    this.setState({
      speedRange: [value[0], value[1]],
      hasSearchCriteria: value[0] == 0 && value[1] == 200 ? false : true,
    });
  };

  handleTimeRangeChange = (value) => {
    this.setState({
      timeRange: [value[0], value[1]],
      hasSearchCriteria: value[0] == 0 && value[1] == 240 ? false : true,
    });
  };

  handleVehicleSelectedChange = (
    occurFromClickRow,
    vehicleList,
    vehicle_id
  ) => {

    let selectedVehicle = vehicleList.filter(function (ele) {
      return ele.vehicle_id == vehicle_id;
    })[0];
    this.setState({
      selectedVehicle: {
        address:
          selectedVehicle.current_location.length > 0 &&
          selectedVehicle.current_location.map((ele) => ele.name).join(", "),
        engine: selectedVehicle.engine,
        speed: selectedVehicle.velocity,
        plate_no: selectedVehicle.plate_no,
        device_code: selectedVehicle.device_code,
        vehicle_code: selectedVehicle.vehicle_code,
        vehicle_type_name: selectedVehicle.vehicle_type_name,
        gps_active_at: selectedVehicle.gps_active_at,
        latest_pos_update_at: selectedVehicle.latest_pos_update_at,
        images: selectedVehicle.img,
        lat: selectedVehicle.latest_lat,
        lng: selectedVehicle.latest_lng,
        driver_license_info: selectedVehicle.driver_license_info,
        driver_license_swiping_status:
          selectedVehicle.driver_license_swiping_status,
        driving_time: selectedVehicle.driving_time,
        PTO: selectedVehicle.PTO,
        boom: selectedVehicle.boom,
        current_location: selectedVehicle.current_location,
        door1: selectedVehicle.door1,
        door2: selectedVehicle.door2,
        door3: selectedVehicle.door3,
        driver1: selectedVehicle.driver1,
        drum_status: selectedVehicle.drum_status,
        is_sos_should_alert: selectedVehicle.is_sos_should_alert,
        meter_status: selectedVehicle.meter_status,
        temperature1: selectedVehicle.temperature1,
        temperature2: selectedVehicle.temperature2,
        vehicle_tel: selectedVehicle.vehicle_tel,
        gps_image_active_at: selectedVehicle.gps_image_active_at,
        RPM: selectedVehicle.RPM,
        OBD: selectedVehicle.OBD,
        trip_status: selectedVehicle.tripStatus,
        passenger_count: selectedVehicle.passengerCount,
        model: selectedVehicle.model,
        start_trip: selectedVehicle.startTrip,
        travel_distance: selectedVehicle.travelDistance,
        color: selectedVehicle.color,
        video: selectedVehicle.video,
        battery: selectedVehicle.battery,
        track_3: selectedVehicle.track_3,
        vehicle_group: selectedVehicle.vehicle_group,
        duration_range: selectedVehicle.duration_range,
        vehicle_id: selectedVehicle.vehicle_id,
        fuel_status: selectedVehicle.fuel_status,
        voltage: selectedVehicle.voltage,
        pw_supply: selectedVehicle.pw_supply,
        satellite: selectedVehicle.satellite,
        sim: selectedVehicle.sim,
        engine_vol_status: selectedVehicle.engine_vol_status || 0,
      },
    });

    if (occurFromClickRow) {
      this.state.map.setCenter({
        lat: selectedVehicle.latest_lat,
        lng: selectedVehicle.latest_lng,
      });
      let zoomLevel = this.state.map.getZoom();
      if (zoomLevel <= 14) {
        this.state.map.setZoom(17);
      }
    }
  };

  handleMasterDataTreeChange = (value) => {
    this.setState({
      masterDataSelectvalue: value,
      hasSearchCriteria: value.length == 2 ? false : true,
    });
  };

  openInfoPanel = (vehicleList, vehicle_id) => {
    let selectedVehicle = vehicleList.filter(function (ele) {
      return ele.vehicle_id == vehicle_id;
    })[0];
    if (this.state.splitterLayoutHNT) {
      if (
        selectedVehicle.device_code.substring(0, 3) == "J40" ||
        selectedVehicle.device_code.substring(0, 3) == "HNT" ||
        selectedVehicle.device_code.substring(0, 3) == "ISU"
      ) {
        this.handleShowHNTLiveStream(true, selectedVehicle.vehicle_id);
        this.props.loadListVehicleHoneytoast(
          {
            company_id: this.props.auth.profile.company_id,
            list_vehicle_id: "",
            orderBy: "plate_no",
            orderType: "asc",
            vehicle_visibility: selectedVehicle.vehicle_id,
          },
          true
        );
      } else {
        this.setState({
          splitterLayoutCustomTable: false,
          splitterLayoutHNT: false,
        });
        this.openNav();
      }
    } else {
      this.setState({ splitterLayoutCustomTable: false });
      this.openNav();
    }

    this.handleVehicleSelectedChange(true, vehicleList, vehicle_id);
  };

  onClickVehicleSelectedChange = (getBool, trackingList, plateNo) => {
    this.handleVehicleSelectedChange(getBool, trackingList, plateNo);
    this.openNav();
  };

  openNav = () => {
    this.setState({
      selectedVehicle: initSelectedVehicle,
      visibleDrawer: true,
    });
  };

  closeNav = () => {
    this.setState({
      width: "0px",
      selectedVehicle: initSelectedVehicle,
      visibleDrawer: false,
    });
  };

  handleApplayButtonClick = (event) => {
    let self = this;
    !this.props.monitoringState.trackingLoading &&
      this.props.loadTrackingInitData({
        companyID: this.props.auth.profile.company_id,
        token: this.props.auth.accessToken,
        selectedTrackingColumns: this.props.auth.profile.config.menu.tracking
          .columns,
        searchName: handleFilter(event),
        deviceType: this.state.deviceType,
        vehicleStatus: this.state.vehicleStatus,
        engineStatus: this.state.engineStatus,
        speedRange: this.state.speedRange,
        timeRange: this.state.timeRange,
        dataTreeFilter: this.state.masterDataSelectvalue,
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        cb: (markers) => {
          typeof markers != "undefined" && self.reCalMarker(markers);
          self.handleFilterCancel();
        },
        previousState: this.props.monitoringState.trackingList,
        cbFailure: this.alertError,
      });
  };

  handleResetButtonClick = (event) => {
    if (this.searchRef.current) {
      this.searchRef.current.input.state.value = "";
    }
    let self = this;
    self.setState({
      searchName: DEFAULT_FILTER_VALUE.searchName,
      vehicleStatus: DEFAULT_FILTER_VALUE.vehicleStatus,
      engineStatus: [],
      speedRange: DEFAULT_FILTER_VALUE.speedRange,
      timeRange: DEFAULT_FILTER_VALUE.timeRange,
      masterDataSelectvalue: DEFAULT_FILTER_VALUE.masterDataSelectvalue,
    });
    !this.props.monitoringState.trackingLoading &&
      this.props.loadTrackingInitData({
        companyID: this.props.auth.profile.company_id,
        token: this.props.auth.accessToken,
        selectedTrackingColumns: this.props.auth.profile.config.menu.tracking
          .columns,
        searchName: DEFAULT_FILTER_VALUE.searchName,
        deviceType: DEFAULT_FILTER_VALUE.deviceType,
        vehicleStatus: DEFAULT_FILTER_VALUE.vehicleStatus,
        engineStatus: [],
        speedRange: DEFAULT_FILTER_VALUE.speedRange,
        timeRange: DEFAULT_FILTER_VALUE.timeRange,
        dataTreeFilter: DEFAULT_FILTER_VALUE.masterDataSelectvalue,
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        cb: (markers) => {
          typeof markers != "undefined" && self.reCalMarker(markers);
          self.handleFilterCancel();
          self.setState({
            hasSearchCriteria: false,
          });
        },
        previousState: this.props.monitoringState.trackingList,
        cbFailure: this.alertError,
      });
  };

  handleApiLoaded = (map, maps, platform = null, mapLayer = null) => {
    if (typeof maps == "undefined" || maps == null) window.location.reload();

    this.setState({
      map: map,
      maps: maps,
      mapAPI:
        typeof maps.TrafficLayer != "undefined"
          ? { ...this.state.mapAPI, trafficLayer: new maps.TrafficLayer() }
          : null,
      mapLayer: mapLayer,
      mapPlatform: platform,
    });
  };

  handleMasterDataTreeUnfocus = (e) => console.log(e.type, e.bubbles);

  onChangeTableLayout = (e) => {
    if (e.target.value === "a") {
      this.setState({
        eleSplitterTableLayout: true,
        showBtnSaveTable: false,
        newColumn: null,
        switchCustomizeTable: false,
      });
    } else {
      this.setState({
        eleSplitterTableLayout: false,
        showBtnSaveTable: false,
        newColumn: null,
        switchCustomizeTable: false,
      });
    }
  };

  handleClearAllToolDrawing = () => {
    this.setState({
      isMenuMapToolsCLick: "",
      distance: null,
      intervalHours: null,
      intervalMins: null,
    });
    if (this.props.auth.profile.map_type == "here") {
      this.state.routePolyline !== null &&
        this.state.map.removeObjects([this.state.routePolyline]);
      this.setState({ routePolyline: null });
    }
    if (this.props.auth.profile.map_type == "google") {
      this.state.routePolyline !== null &&
        this.state.routePolyline.setMap(null);
    }
    this.clearPOIMarker();
    this.clearDistanceMarker();
    this.clearAreaMarker();
    this.clearPlaceMarker();
  };

  clearPlaceMarker = () => {
    if (this.props.auth.profile.map_type == "here") {
      if (this.state.placeMarker.length > 0) {
        this.state.map.removeObjects(this.state.placeMarker);
      }
    }
    this.setState({
      placeMarker: [],
    });
  };

  clearPOIMarker = () => {
    if (this.props.auth.profile.map_type == "google") {
      if (this.state.poiMarker != null) {
        this.state.poiMarker.setMap(null);
        this.handleDescriptionBoxDrawerClose();
      }
      this.setState({
        showPOI: false,
        poiMarker: null,
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
      });
      this.state.maps.event &&
        this.state.maps.event.clearListeners(this.state.map, "click");
    } else {
      if (this.state.poiMarker != null) {
        this.state.map.removeObjects([this.state.poiMarker]);
        this.handleDescriptionBoxDrawerClose();
      }
      this.setState({
        showPOI: false,
        poiMarker: null,
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
      });
      this.state.maps && this.state.map.removeEventListener("tap", () => false);
    }
  };

  clearDistanceMarker = () => {
    if (this.props.auth.profile.map_type == "google") {
      if (this.state.distanceMarker != []) {
        this.state.distanceMarker.map((value, index) => {
          value.setMap(null);
        });
      }

      if (this.state.distancePolyline != []) {
        this.state.distancePolyline.map((value, index) => {
          value.setMap(null);
        });
      }

      this.handleDescriptionBoxDrawerClose();
      this.state.maps.event &&
        this.state.maps.event.clearListeners(this.state.map, "click");

      this.setState({
        showDistance: false,
        distanceMarker: [],
        distancePolyline: [],
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
      });
    } else {
      this.handleDescriptionBoxDrawerClose();
      this.state.maps && this.state.map.removeEventListener("tap", () => false);
      if (this.state.distanceMarker.length > 0)
        this.state.map.removeObjects(this.state.distanceMarker);
      if (this.state.distancePolyline.length > 0)
        this.state.map.removeObjects(this.state.distancePolyline);

      this.state.distanceMarker.map((ele) => {
        this.state.distanceLineString.removePoint({
          lat: ele.b.lat,
          lng: ele.b.lng,
        });
      });

      this.setState({
        showDistance: false,
        distanceMarker: [],
        distancePolyline: [],
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
      });
    }
  };

  clearAreaMarker = () => {
    if (this.props.auth.profile.map_type == "google") {
      if (this.state.areaPolyline != []) {
        this.state.areaPolyline.map((value, index) => {
          value.setMap(null);
        });
      }

      this.handleDescriptionBoxDrawerClose();
      this.state.maps.event &&
        this.state.maps.event.clearListeners(this.state.map, "click");

      this.setState({
        showArea: false,
        areaPolyline: [],
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
        vehiclesInMarker: [],
      });
    } else {
      if (this.state.areaPolyline.length > 0) {
        this.state.map.removeObjects(this.state.areaPolyline);
      }

      this.state.maps && this.state.map.removeEventListener("tap", () => false);
      this.handleDescriptionBoxDrawerClose();
      this.setState({
        showArea: false,
        areaPolyline: [],
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
        hereMapDraw: 0,
      });
    }
  };

  callApiPinPoinOfInterest = (self, lat, lng) => {
    let poiMarker = null;
    let iconImg =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAElklEQVRoge2aT6gVZRjGf8/lIiIuRERcRNCcjXfIEImWIfeARIvCVhEFroKWLQxahASJRIRJRkZk5MZLixCzoptzkBZCFnETnbPxDtKihchFREQuh/O0mJl75pz778yfIy16Lx/zLM687/N83zPvzDdzRcORtMMnjWcx+yTtBvq27wB/SeoEUfxPk/XURJKkHQI8b/weZhaYEmANijiFPWBe6P0giq81Ubu2gMXZcJvEZ4YjBaID0gbJGBVxX3AaczToxMt16tcSkLTDHcDPwHO4kG18fBl4OYjih1U5VBaw2A6nsX+ROOiMmIB1caHgCL4odDiI4n4VHtNVBQjeMRzEazK/b/Sj5JtGU8A+zIuIbcDoSryEeBM4U5FH+Uja4W7jRcz2IUsAQmeQ3w2i7r3iOYvtcBdwEvv1NVLelXgqiLoPynKZKntCxvWI0HZJrPxJgI4h3holD9CK4rvAGxKfSOnvC2OX0atVuFQSIDhse8XLtgGuSv4giOJ1z2tFMZijwI3iuZmjDlfhUlpAMjszbbw/971xbsSTQdTd9EIMOt0ecAo7F57neTZpz5SlU/4itrRLsBUYuoIMV0qk+TW13FDsJM37qAyf8hZyKnrUQsJLJbIsjVoo41J6QksLEH5g6KP0zpp2T2G0p0SaPThrXIM8y5ScfaiyAtJ94YfCSNnAgF8YN4Xxofy8Qp47QRT3ytIpLSC9YyoxeetX1kV0NGmHWzc7f7Edbse8nV6/mYQU3yrLBareB+zrIPBKcWz22v48aYfr5kza4RbwN0hP5MLTEMCNKlwqCQB+H7VQijmC+SmZndmbPWLnxElmZ/ZjIsErK78vWMjwWxUilZ6FBFcHD2TCK2YSlg8BNwULSTuMnU7S00bPgLPfAzZSjkH46mMTgLQALGF2DnWSAZ4yHEAcwNnGJtvhFG2zguXE6HYVKpUsFERxT3B5tYXWwWyC0Xxrg0eQxgWk4R+KXWhdvLrbrMLA91VZVBZgdAmrlxMpdqQhzDq2GeD7QKcqj8oCWlG8JNGpbSFzKYji0nfg2gKyOF/XQuDzdQjUE2BfMH6Ys7azVlTEkLVZr4XvAPN1KNQSEHS692RdWGOHNTwKu7ZhzFzQ6dZ6rVLXQhi+Suc0n9URnG1a7GGcHvV13fq1BUi+Ar41sI0ZwmSCNIyNr0ks1K1fW0AQdftCX5a2EPpio/3zYxMAYHzW+FGKx7GQl4C5Jmo3IqAVde8K5sa1kKSzdV4nFqMRAQBGp8a0UA/4tKm6jQkALwCdMSz0XRDFfzdVtTEBragL8NGmFrI/bqomNLoCYDwv6foGFroiqZEPG3k0KqCVvpn7cAMLnWiidRajUQFZfItJVlkI/pBU67lnrWhcQBDFPUknVlvIx5uefZjMCgCcA24XLHQddHEShSYiIIjiZeD4wEIcCzrVPiFtFpNaAYBzkm5J+lMwkdmfeCTt8LWkHY79zrRKVP7IN2bMGSZinf/jvxKN/K8EsCUb09nIm0N+7BeOvWwsZ6NWNCFgK7CD8h2tD9yjwleZYjS1ApCuQP6da6MVyFeh9uwD/Asypo/TAASupgAAAABJRU5ErkJggg==";

    if (self.state.showPOI) {
      if (this.props.auth.profile.map_type == "google") {
        // claer old point marker
        if (self.state.poiMarker != null) self.state.poiMarker.setMap(null);
        poiMarker = new self.state.maps.Marker({
          icon: iconImg,

          position: { lat, lng },
          map: self.state.map,
        });

        self.setState({ poiMarker: poiMarker });
      } else {
        // claer old point marker
        if (self.state.poiMarker != null)
          self.state.map.removeObjects([self.state.poiMarker]);

        let htmlMarker = renderToString(<img src={iconImg} />);
        // Create a marker icon from an image URL:
        var icon = new self.state.maps.map.DomIcon(htmlMarker),
          coords = { lat: lat, lng: lng },
          marker = new self.state.maps.map.DomMarker(coords, { icon: icon });

        self.state.map.addObject(marker);
        self.setState({ poiMarker: marker });
      }

      fetch(
        `https://api.longdo.com/map/services/address?lon=${lng}&lat=${lat}&nopostcode=1&noelevation=1&noroad=1&noaoi=1&nowater=1&key=823962a623a584cb9f7e3475fe3c90b2`
      )
        .then((response) => response.json())
        .then((data) => {
          try {
            let { subdistrict, district, province, geocode, country } = data;
            self.setState({
              descriptionBoxDrawerWidth: "300px",
              descriptionBoxHeader: `${subdistrict} ${district} ${province} ${geocode} ${country}`,
              pinLat: lat,
              pinLng: lng,
            });
          } catch (ex) {
            self.setState({
              descriptionBoxDrawerWidth: "300px",
              descriptionBoxHeader: `Cannot get location Infomation`,
            });
            console.log(ex);
          }
        });
    }
  };

  handleShowHNTLiveStream = (e, vehIdSelect) => {
    this.setState({
      splitterLayoutHNT: e,
      splitterLayoutCustomTable: e,
      vehIdSelect,
    });
  };

  handlePinPointOfInterest = (e) => {
    let self = this;

    this.setState({
      isMenuMapToolsCLick: "pushpin",
    });

    if (this.state.showPOI) {
      this.clearPOIMarker();
    } else {
      this.setState({ showPOI: true, showDistance: false });
      this.clearDistanceMarker();
      this.clearAreaMarker();
    }

    if (this.props.auth.profile.map_type == "google") {
      this.state.maps.event.addListener(this.state.map, "click", function (
        event
      ) {
        self.callApiPinPoinOfInterest(
          self,
          event.latLng.lat(),
          event.latLng.lng()
        );
      });
    } else {
      let self = this;
      self.state.map &&
        self.state.map.addEventListener("tap", function (evt) {
          var coord = self.state.map.screenToGeo(
            evt.currentPointer.viewportX,
            evt.currentPointer.viewportY
          );
          self.callApiPinPoinOfInterest(
            self,
            Math.abs(coord.lat.toFixed(4)),
            Math.abs(coord.lng.toFixed(4))
          );
        });
    }
  };

  handleFindDistance = (e) => {
    let self = this;
    let iconImg =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADMUlEQVRoge2Zv2sUURDHP3McwUpEDsuwlVhYRNNYb+wtrFLmf7gUIX+Cy5G/wb9AJGDldso1IlgpiCxBrUKwEglhx+Leu3v3Y293dt+RFBl4vO/dvZ03s/N9M7N7glGS99lzRF8CfRBA3S91WNznSlyi8q7YG76x2CP1SwLj8+wZ8AHV3uRKAVVqsU1eFOnwbdPFPaPyFOghwtSwOqwAOpmbYNizGGRzQOmh6jGNsF1MNvVNqkNKiBrxop4a3FCsEXBzYFgd1uDaJtgoxgi4HaZ3SkP8FeELSjm1xPuznHgege7M6fRYbWGwOaChFQTW8RuR3SId/m2iJsmzPiqfER4vZ1tbGGwUCpLMAj5rajxAkQ6vEL6v/HGjEaCCQm2yja8Ti4f4WijUIntM6sSyquuhUEwxRsBaid1dkln6E5kVrlbK/LxIz2ZipJDOQj/H3Rah8LrmiiPmm2GkUETOSHD6lRk27tGOQn6eq8wtJTR+45X4lkKLum4MhXiY5NmDpiqSPLuLsjPTd+0U4j7wLcmzHxUXThc6vI0wgO4UMnaja8N7D3i69O20E5UVXWnQcnt8TRRa09+v4PlabJNYFFqDxYZvXBayRsG4h7Gdpl3R0gDU4Y32QvCzWy1o9DT/y6LR+lD/Gjg1XjOb6zA6BkYW9ebbmeTZANVPwLbtytrXkBcIT4r08Myi1ZxGi3R4jsg+IpeTXqbpYB0uETmwGt/KAefER+C40eJmteIEaPw+NJRWDjgZAaeosnbgZ1ZjYQxyVKTDVkZ0ai+T/NUApMV58KIXILtFOiza2tAlAhTp4TmwD1xVLqqkDSXIQRfjoaMDMD0PR3YKcWL5H6BKOjvgZITIqSELjYGjGBtHe8RK8mwALJ8H30JP03533ocSKwKT+gD7qF6uoVAJdOZ9KNEcAHceRI4rKYScgHTmfShRHQBAXX2YffZgjNA631fJJt5uruqXLtz/B0XsveJHgKV+qUTi8j6UjTgAYb+k0Xkfiv2JzCYjkF5s3t/KTZJYWWjLjb4b/mz5uQzmKzcu3egkMRy4w+StnDUhlMAf4F+XzWPWgS0mTtRFwEeh890H+A8e4J/aNz46/gAAAABJRU5ErkJggg==";
    this.setState({
      isMenuMapToolsCLick: "findDistance",
    });
    if (this.state.showDistance) {
      this.clearDistanceMarker();
    } else {
      this.setState({ showDistance: true, showPOI: false });
      this.clearPOIMarker();
      this.clearAreaMarker();
    }

    if (this.props.auth.profile.map_type == "google") {
      let poly = new this.state.maps.Polyline({
        strokeColor: "#2F7DFF",
        strokeOpacity: 0.6,
        strokeWeight: 4,
      });
      poly.setMap(this.state.map);

      this.state.maps.event.addListener(this.state.map, "click", function (
        event
      ) {
        if (self.state.showDistance) {
          let latlng = JSON.parse(
            JSON.stringify(event.latLng.toJSON(), null, 2)
          );

          let path = poly.getPath();
          path.push(event.latLng);

          let lengthInMeters = self.state.maps.geometry.spherical.computeLength(
            path
          );

          let marker = new self.state.maps.Marker({
            icon: iconImg,
            position: event.latLng,
            title: "#" + path.getLength(),
            map: self.state.map,
          });

          self.setState({
            pinLat: latlng.lat,
            pinLng: latlng.lng,
            distanceMarker: [...self.state.distanceMarker, marker],
            distancePolyline: [...self.state.distancePolyline, poly],
            descriptionBoxDrawerWidth: "300px",
            descriptionBoxHeader: `ระยะทาง : ${Number(
              lengthInMeters / 1000
            ).toFixed(2)} Km.`,
          });
        }
      });
    } else {
      let prevPoint = null;
      let lengthInMeters = 0;
      let lineString = null;
      if (this.state.distanceLineString == null)
        lineString = new self.state.maps.geo.LineString();
      else lineString = this.state.distanceLineString;
      self.state.map &&
        self.state.map.addEventListener("tap", function (evt) {
          if (self.state.showDistance) {
            var coord = self.state.map.screenToGeo(
              evt.currentPointer.viewportX,
              evt.currentPointer.viewportY
            );

            let htmlMarker = renderToString(
              <img style={{ margin: "-50px 0px 0px -23px" }} src={iconImg} />
            );
            // Create a marker icon from an image URL:
            var icon = new self.state.maps.map.DomIcon(htmlMarker),
              coords = { lat: coord.lat, lng: coord.lng },
              marker = new self.state.maps.map.DomMarker(coords, {
                icon: icon,
              });

            self.state.map.addObject(marker);

            if ([...self.state.distanceMarker, marker].length > 0) {
              [marker].map((ele) => {
                if (prevPoint != null) {
                  lengthInMeters += calcCrowDistance(
                    prevPoint.b.lat,
                    prevPoint.b.lng,
                    ele.b.lat,
                    ele.b.lng
                  );
                }

                lineString.pushPoint({ lat: ele.b.lat, lng: ele.b.lng });
              });
              prevPoint = marker;

              if ([...self.state.distanceMarker, marker].length > 1) {
                let polyLine = new self.state.maps.map.Polyline(lineString, {
                  style: { lineWidth: 4 },
                });
                self.state.map.addObject(polyLine);
                self.setState({
                  distanceMarker: [...self.state.distanceMarker, marker],
                  distancePolyline: [...self.state.distancePolyline, polyLine],
                  distanceLineString: lineString,
                  descriptionBoxDrawerWidth: "300px",
                  descriptionBoxHeader: `ระยะทาง : ${Number(
                    lengthInMeters
                  ).toFixed(2)} กม.`,
                });
              } else {
                self.setState({
                  distanceMarker: [...self.state.distanceMarker, marker],
                  distanceLineString: lineString,
                });
              }
            }
          }
        });
    }
  };

  handleFindArea = (e) => {
    this.setState({
      isMenuMapToolsCLick: "findArea",
    });
    if (this.state.showArea) {
      this.clearAreaMarker();
    } else {
      this.setState({ showArea: true, showPOI: false });
      this.clearPOIMarker();
      this.clearDistanceMarker();
    }

    if (this.props.auth.profile.map_type == "google") {
      new this.state.maps.Polyline();

      let self = this;
      let polygonCoords = [];
      let polygonLayer = null;

      this.state.maps.event.addListener(this.state.map, "click", function (
        event
      ) {
        if (self.state.showArea) {
          polygonCoords.push({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          });

          if (polygonLayer != null) polygonLayer.setMap(null);
          polygonLayer = new self.state.maps.Polygon({
            path: polygonCoords,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            editable: true,
            geodesic: true,
          });

          self.state.maps.event.addListener(
            polygonLayer.getPath(),
            "remove_at",
            function () {
              subFnFindArea();
            }
          );

          self.state.maps.event.addListener(
            polygonLayer.getPath(),
            "insert_at",
            function () {
              subFnFindArea();
            }
          );

          self.state.maps.event.addListener(
            polygonLayer.getPath(),
            "set_at",
            function () {
              subFnFindArea();
            }
          );

          subFnFindArea();

          function subFnFindArea() {
            let lengthInMeters = self.state.maps.geometry.spherical.computeArea(
              polygonLayer.getPath()
            );

            lengthInMeters > 0
              ? (lengthInMeters = (lengthInMeters / 1000).toFixed(2))
              : (lengthInMeters = lengthInMeters);

            polygonLayer.setMap(self.state.map);
            let vehicleAmount = 0;
            let vehicleIds = [];

            for (
              let i = 0;
              i < self.props.monitoringState.trackingList.length;
              i++
            ) {
              if (
                checkInsidePolygon(
                  {
                    lat: self.props.monitoringState.trackingList[i].lat,
                    lng: self.props.monitoringState.trackingList[i].lng,
                  },
                  polygonLayer
                    .getPath()
                    .getArray()
                    .map((item) => ({ lat: item.lat(), lng: item.lng() }))
                )
              ) {
                vehicleIds.push(
                  self.props.monitoringState.trackingList[i].vehicle_id
                );
                vehicleAmount++;
              }
            }

            self.setState({
              areaPolyline: [...self.state.areaPolyline, polygonLayer],
              vehiclesInMarker: vehicleIds,
              descriptionBoxDrawerWidth: "300px",
              descriptionBoxHeader: `พื้นที่ : ${Number(
                lengthInMeters / 1000
              ).toFixed(2)} Square km.`,
              descriptionBoxDetail: `จำนวนพาหนะ : ${vehicleAmount} คัน`,
            });
          }
        }
      });
    } else {
      let lengthInMeters = 0;
      let tempMaingroup = null;
      let pointerCount = 0;
      let tempPointMarker = [];

      let iconImg =
        '<div style="display: block; width: 11px; height: 11px; cursor: pointer; touch-action: none; position: absolute; left: 0px; top: 0px;"><div style="position: absolute; left: -5px; top: -5px; width: 9px; height: 9px; border-width: 1px; border-style: solid; border-radius: 6px; background-color: white; border-color: rgb(255, 0, 0);"></div></div>';
      let self = this;
      let serieOfLatLng = [];
      let latLngs = [];

      if (this.state.areaPolyline != null) {
        self.state.map.removeObjects(this.state.areaPolyline);
      }
      let randomnumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
      this.setState({ hereMapDraw: randomnumber });
      self.state.map &&
        self.state.map.addEventListener("tap", function (evt) {
          pointerCount++;
          if (self.state.showArea && self.state.hereMapDraw == randomnumber) {
            var coord = self.state.map.screenToGeo(
              evt.currentPointer.viewportX,
              evt.currentPointer.viewportY
            );

            serieOfLatLng.push(Math.abs(coord.lat.toFixed(4)));
            serieOfLatLng.push(Math.abs(coord.lng.toFixed(4)));
            serieOfLatLng.push(0);
            latLngs.push({
              lat: Math.abs(coord.lat.toFixed(4)),
              lng: Math.abs(coord.lng.toFixed(4)),
            });

            if (self.state.poiMarker != null)
              self.state.map.removeObjects([self.state.poiMarker]);

            if (self.state.areaPolyline != null) {
              self.state.map.removeObjects(self.state.areaPolyline);
            }

            let polygonObj = new self.state.maps.geo.LineString(serieOfLatLng);
            let polygonObj2 = new self.state.maps.geo.Polygon(polygonObj);
            let polygon = new self.state.maps.map.Polygon(polygonObj2, {
              style: {
                fillColor: "rgba(255, 0, 0, 0.35)",
                strokeColor: "rgba(255, 0, 0, 1)",
                lineWidth: 3,
              },
            });
            let verticeGroup = new self.state.maps.map.Group({
              visibility: false,
            });
            let mainGroup = new self.state.maps.map.Group({
              volatility: true, // mark the group as volatile for smooth dragging of all it's objects
              objects: [polygon, verticeGroup],
            });
            let polygonTimeout;

            // ensure that the polygon can receive drag events
            polygon.draggable = true;

            // create markers for each polygon's vertice which will be used for dragging
            polygon
              .getGeometry()
              .getExterior()
              .eachLatLngAlt(function (lat, lng, alt, index) {
                var vertice = new self.state.maps.map.DomMarker(
                  { lat, lng },
                  {
                    icon: new self.state.maps.map.DomIcon(iconImg, {
                      anchor: { x: 10, y: 10 },
                    }),
                  }
                );
                vertice.draggable = true;
                vertice.setData({ verticeIndex: index });
                verticeGroup.addObject(vertice);
              });

            // add group with polygon and it's vertices (markers) on the map
            self.state.map.addObject(mainGroup);
            tempMaingroup = mainGroup;

            mainGroup.addEventListener(
              "pointerenter",
              function (evt) {
                if (polygonTimeout) {
                  clearTimeout(polygonTimeout);
                  polygonTimeout = null;
                }

                // show vertice markers
                verticeGroup.setVisibility(true);
              },
              true
            );

            // event listener for vertice markers group to change the cursor to pointer
            verticeGroup.addEventListener(
              "pointerenter",
              function (evt) {
                document.body.style.cursor = "pointer";
              },
              true
            );

            // event listener for vertice markers group to change the cursor to default
            verticeGroup.addEventListener(
              "pointerleave",
              function (evt) {
                document.body.style.cursor = "default";
              },
              true
            );

            // event listener for vertice markers group to resize the geo polygon object if dragging over markers
            verticeGroup.addEventListener(
              "drag",
              function (evt) {
                var pointer = evt.currentPointer,
                  geoLineString = polygon.getGeometry().getExterior(),
                  geoPoint = self.state.map.screenToGeo(
                    pointer.viewportX,
                    pointer.viewportY
                  );

                // set new position for vertice marker
                evt.target.setGeometry(geoPoint);

                // set new position for polygon's vertice
                geoLineString.removePoint(evt.target.getData()["verticeIndex"]);
                geoLineString.insertPoint(
                  evt.target.getData()["verticeIndex"],
                  geoPoint
                );
                polygon.setGeometry(
                  new self.state.maps.geo.Polygon(geoLineString)
                );

                // stop propagating the drag event, so the map doesn't move
                evt.stopPropagation();

                lengthInMeters = computeArea(latLngs);
              },
              true
            );

            //////////////////////////////

            let htmlMarker = iconImg;
            // Create a marker icon from an image URL:
            let icon = new self.state.maps.map.DomIcon(htmlMarker);
            let coords = { lat: coord.lat, lng: coord.lng };
            let marker = new self.state.maps.map.DomMarker(coords, {
              icon: icon,
            });

            if (pointerCount < 3) {
              self.state.map.addObject(marker);
              tempPointMarker.push(marker);
            } else {
              if (tempPointMarker.length > 0)
                self.state.map.removeObjects(tempPointMarker);
              tempPointMarker = [];
              lengthInMeters = computeArea(latLngs);
              let vehicleAmount = 0;
              for (
                let i = 0;
                i < self.props.monitoringState.trackingList.length;
                i++
              ) {
                if (
                  checkInsidePolygon(
                    {
                      lat:
                        self.props.monitoringState.trackingList[i].latest_lat,
                      lng:
                        self.props.monitoringState.trackingList[i].latest_lng,
                    },
                    latLngs
                  )
                ) {
                  vehicleAmount++;
                }
              }

              self.setState({
                areaPolyline: [mainGroup],
                descriptionBoxDrawerWidth: "300px",
                descriptionBoxHeader: `พื้นที่ : ${Number(
                  lengthInMeters
                )} Square km.`,
                descriptionBoxDetail: `จำนวนพาหนะ : ${vehicleAmount} คัน`,
              });
            }
          }
        });
    }
  };

  handleChangeCustomizeTable = (state, newColumn) => {
    let data = newColumn.map(({ width, dataIndex }) => ({ width, dataIndex }));
    if (this.state.eleSplitterTableLayout) {
      this.setState({
        showBtnSaveTable: state,
        newColumn: [
          {
            Column: data,
          },
        ],
      });
    } else {
      this.setState({
        showBtnSaveTable: state,
        newColumn: [
          {
            Column: data,
          },
        ],
      });
    }
  };

  handleMapTypeSatellite = (e) => {
    this.setState({
      isMenuMapTypeClick: "satellite",
    });
    if (this.props.auth.profile.map_type == "google") {
      this.state.map.setMapTypeId("hybrid");
    } else {
      this.state.map.setBaseLayer(this.state.mapLayer.raster.satellite.map);
    }
  };

  handleMapTypeTerrain = (e) => {
    this.setState({
      isMenuMapTypeClick: "terrain",
    });

    if (this.props.auth.profile.map_type == "google") {
      this.state.map.setMapTypeId("roadmap");
    } else {
      if (this.state.isShowTrafficClick) {
        this.hereMapShowTraffic();
      } else {
        this.state.map.setBaseLayer(this.state.mapLayer.vector.normal.map);
      }
    }
  };

  handleGroupVehicleByLocation = debounce((e) => {
    let self = this;
    this.setState({
      isGroupByLocationClick: !this.state.isGroupByLocationClick,
    });
    if (this.state.showLocationLayer) {
      this.clearLocationMarker(true);
    } else {
      this.clearLocationMarker(false);
      this.generateLocationMarker(self);
    }
  }, 1200);

  generateLocationMarker = (self) => {
    if (typeof self.state.map !== "undefined") {
      let ne = self.state.map.getBounds().getNorthEast();
      let sw = self.state.map.getBounds().getSouthWest();
      let postData = {
        bounds: [
          { lat: ne.lat(), lng: ne.lng() },
          { lat: ne.lat(), lng: sw.lng() },
          { lat: sw.lat(), lng: sw.lng() },
          { lat: sw.lat(), lng: ne.lng() },
        ],
        company_id: this.props.auth.profile.company_id,
        location_visibility: this.props.auth.profile.location_visibility,
      };

      if (
        self.state.isFilterLocationTypeClick &&
        self.state.filter_location_type
      ) {
        // มีการกรองประเภทสถานที่
        let filter_location_type = self.state.filter_location_type;
        postData.location_type = filter_location_type;
      }

      // find loactions
      this.setState({ locationLoading: true });
      fetch(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/checklocationfortracking `,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          let locationPolygonList =
            self.state.mapAPI.locationLayer != null
              ? self.state.mapAPI.locationLayer
              : [];

          let linkNameWithLocationPolygonList =
            self.state.mapAPI.locationLayer != null
              ? self.state.mapAPI.locationLinkWithNameLayer
              : [];

          data.map((location, index) => {
            let locationHasGeoFence = false;
            let locationPolygon = null;
            let linkNameWithPolygon = null;
            let vehicleAmount = 0;

            if (location.geo_fence != "" && location.geo_fence != null) {
              locationHasGeoFence = true;
              let latLng = location.geo_fence.split(",");

              let polygonCoords = [];
              for (let i = 0; i < latLng.length; i = i + 2) {
                polygonCoords.push({
                  lat: parseFloat(latLng[i]),
                  lng: parseFloat(latLng[i + 1]),
                });
              }
              locationPolygon = new self.state.maps.Polygon({
                paths: polygonCoords,
                strokeColor: location.base_color_code,
                strokeOpacity: 1,
                strokeWeight: 0.8,
                fillColor: location.base_color_code,
                fillOpacity: 0.2,
              });

              linkNameWithPolygon = new self.state.maps.Polyline({
                path: [
                  polygonCoords[0],
                  {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lng),
                  },
                ],
                strokeOpacity: 0.5,
                strokeColor: "#8415FF",
                strokeWeight: 0.8,
              });

              let vehicleList = [];
              for (
                let i = 0;
                i < self.props.monitoringState.trackingList.length;
                i++
              ) {
                if (
                  checkInsidePolygon(
                    {
                      lat:
                        self.props.monitoringState.trackingList[i].latest_lat,
                      lng:
                        self.props.monitoringState.trackingList[i].latest_lng,
                    },
                    polygonCoords
                  )
                ) {
                  self.props.monitoringState.trackingList[
                    i
                  ].insideLocation = true;
                  self.props.monitoringState.trackingList[i].locationName =
                    location.name;
                  vehicleAmount++;
                }
                vehicleList.push(self.props.monitoringState.trackingList[i]);
              }
            } else {
              locationPolygon = new self.state.maps.Circle({
                strokeColor: location.base_color_code,
                strokeOpacity: 1,
                strokeWeight: 0.8,
                fillColor: location.base_color_code,
                fillOpacity: 0.2,
                center: {
                  lat: parseFloat(location.lat),
                  lng: parseFloat(location.lng),
                },
                radius: location.radius,
              });

              for (
                let i = 0;
                i < self.props.monitoringState.trackingList.length;
                i++
              ) {
                if (
                  getDistanceFromPointToPoint(
                    parseFloat(
                      self.props.monitoringState.trackingList[i].latest_lat
                    ),
                    parseFloat(
                      self.props.monitoringState.trackingList[i].latest_lng
                    ),
                    parseFloat(location.lat),
                    parseFloat(location.lng),
                    parseFloat(location.radius)
                  )
                ) {
                  self.props.monitoringState.trackingList[
                    i
                  ].insideLocation = true;
                  self.props.monitoringState.trackingList[i].locationName =
                    location.name;
                  vehicleAmount++;
                }
              }
            }

            if (
              !self.state.locationInfoMarkerLists.find(
                (ele) => location.id == ele.key
              )
            ) {
              self.setState({
                locationInfoMarkerLists: [
                  ...self.state.locationInfoMarkerLists,

                  <IconWithShowTootip
                    isshowetitle={true}
                    title={`${location.name} (${vehicleAmount} คัน)`}
                    image={
                      location.image_path != ""
                        ? `${process.env.REACT_APP_API_REPORT_LOGIN}${location.image_path}`
                        : location.image_base64
                    }
                    key={location.id}
                    lat={parseFloat(location.lat)}
                    lng={parseFloat(location.lng)}
                    zoomLevel={self.state.map.getZoom()}
                    alwaysShowTooltip={self.state.showLocationName}
                  />,
                ],
              });

              locationPolygon.setMap(self.state.map);
              locationPolygonList.push(locationPolygon);

              if (linkNameWithPolygon != null) {
                linkNameWithPolygon.setMap(self.state.map);
                linkNameWithLocationPolygonList.push(linkNameWithPolygon);
              }
            }
          });
          return { locationPolygonList, linkNameWithLocationPolygonList };
        })
        .then((obj) => {
          self.setState({
            showLocationLayer: true,
            mapAPI: {
              ...self.state.mapAPI,
              locationLayer: obj.locationPolygonList,
              locationLinkWithNameLayer: obj.linkNameWithLocationPolygonList,
            },
          });
        })
        .then(() => self.setState({ locationLoading: false }));
    }
  };

  clearLocationMarker = (resetShowLocationLayer) => {
    if (this.state.mapAPI.locationLayer !== null) {
      this.state.mapAPI.locationLayer.map((value, index) => {
        value.setMap(null);
      });
      this.state.mapAPI.locationLinkWithNameLayer.map((value, index) => {
        value.setMap(null);
      });
      this.setState({
        showLocationLayer: resetShowLocationLayer ? false : true,
        locationInfoMarkerLists: [],
        mapAPI: {
          ...this.state.mapAPI,
          locationLayer: null,
          locationLinkWithNameLayer: null,
        },
      });
    }
  };

  hereMapShowTraffic = () => {
    let trafficService = this.state.mapPlatform.getMapTileService({
      type: "traffic",
    });

    let trafficLayer = trafficService.createTileLayer(
      "traffictile",
      "normal.traffic.day",
      256,
      "png"
    );

    this.state.map.setBaseLayer(trafficLayer);
  };

  handleShowTrafficLayer = (e) => {
    this.setState({
      isShowTrafficClick: !this.state.isShowTrafficClick,
    });
    if (this.props.auth.profile.map_type == "google") {
      if (this.state.showTrafficLayer) {
        this.state.mapAPI.trafficLayer.setMap(null);
        this.setState({
          showTrafficLayer: false,
        });
      } else {
        this.state.mapAPI.trafficLayer.setMap(this.state.map);
        this.setState({
          showTrafficLayer: true,
        });
      }
    } else {
      if (this.state.showTrafficLayer) {
        this.state.map.setBaseLayer(this.state.mapLayer.vector.normal.map);
        this.setState({
          showTrafficLayer: false,
        });
      } else {
        if (this.state.isMenuMapTypeClick != "satellite") {
          this.hereMapShowTraffic();
        }
        this.setState({
          showTrafficLayer: true,
        });
      }
    }
  };

  handleZoomFocusIncrease = (e) => {
    let zoomLevel = this.state.map.getZoom();
    zoomLevel++;
    this.state.map && this.state.map.setZoom(zoomLevel);
  };

  handleZoomFocusDecrease = (e) => {
    let zoomLevel = this.state.map.getZoom();
    zoomLevel--;
    this.state.map && this.state.map.setZoom(zoomLevel);
  };

  handelPoiDrawerOpen = () => {
    this.setState({
      descriptionBoxDrawerWidth: "300px",
    });
  };

  handleDescriptionBoxDrawerClose = () => {
    this.setState({
      descriptionBoxDrawerWidth: "0px",
    });
  };

  handleSearchPlace = (value) => {
    if (value.length >= 2) {
      if (this.state.searchSource == "internet") {
        fetch(
          //`https://search.longdo.com/mapsearch/json/search?key=${this.props.auth.profile.longdo_map_key}&keyword=${value}`
          `https://search.longdo.com/mapsearch/json/search?key=a05187867c5f59143812097abdf07602&keyword=${value}`
        )
          .then((response) => response.json())
          .then((data) => {
            let placeMarker = [];

            if (data["data"].length > 0) {
              data["data"] = data["data"].filter((value, index) => {
                if (value.type == "poi") {
                  placeMarker.push(
                    <PlaceMarker
                      key={index}
                      img="img/free-map-marker-icon-3.jpg"
                      title={value.name}
                      lat={value.lat}
                      lng={value.lon}
                    />
                  );
                  return true;
                }
                return false;
              });
            }

            if (this.props.auth.profile.map_type == "google") {
              if (this.state.maps != null) {
                let bounds = new this.state.maps.LatLngBounds();
                let position = null;

                for (let i = 0; i < data["data"].length; i++) {
                  position = new this.state.maps.LatLng(
                    data["data"][i].lat,
                    data["data"][i].lon
                  );

                  bounds.extend(position);
                }

                if (position !== null) {
                  this.state.map.fitBounds(bounds);
                } else {
                  message.error("ไม่พบข้อมูลสถานที่");
                }
              }
              this.setState({
                placeMarker: placeMarker,
              });
            } else {
              let temp = [];

              if (this.state.placeMarker.length > 0) {
                this.state.map.removeObjects(this.state.placeMarker);
              }

              placeMarker &&
                placeMarker.map((ele) => {
                  let htmlMarker = renderToString(ele);

                  // Create a marker icon from an image URL:
                  var icon = new this.state.maps.map.DomIcon(htmlMarker),
                    coords = { lat: ele.props.lat, lng: ele.props.lng },
                    marker = new this.state.maps.map.DomMarker(coords, {
                      icon: icon,
                    });

                  if (typeof ele.props.onClick != "undefined") {
                    new this.state.maps.map.Group({
                      objects: [marker],
                    });
                  }

                  // Add the marker to the map:
                  this.state.map.addObject(marker);
                  temp.push(marker);
                });
              this.setState({
                placeMarker: temp,
              });
            }
          });
      } else {
        this.searchLocation(this, value, this.state.searchLocationType, 100);
      }
    }
  };

  changeOpacity(evt) {
    evt.target.style.opacity = 0.6;
  }

  changeOpacityToOne(evt) {
    evt.target.style.opacity = 1;
  }

  searchLocation = (self, search_str, location_type, limit) => {
    if (typeof self.state.map !== "undefined") {
      if (this.props.auth.profile.map_type == "here") {
        if (this.state.placeMarker.length > 0) {
          this.state.map.removeObjects(this.state.placeMarker);
        }
      }

      let ne = self.state.map.getBounds().getNorthEast();
      let sw = self.state.map.getBounds().getSouthWest();
      let postData = {
        bounds: [
          { lat: ne.lat(), lng: ne.lng() },
          { lat: ne.lat(), lng: sw.lng() },
          { lat: sw.lat(), lng: sw.lng() },
          { lat: sw.lat(), lng: ne.lng() },
        ],
        company_id: this.props.auth.profile.company_id,
        location_visibility: this.props.auth.profile.location_visibility,
        limit: limit,
        search_str: search_str,
        location_type: location_type,
      };

      this.setState({
        placeMarker: [],
      });

      // find loactions
      this.setState({ locationLoading: true });
      fetch(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/checklocationfortracking `,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          let placeMarker = [];
          let bounds = new self.state.maps.LatLngBounds();
          data.map((location, index) => {
            placeMarker.push(
              <IconMarker
                isshowetitle={true}
                title={`${location.name}`}
                image={
                  location.image_path != ""
                    ? `${process.env.REACT_APP_API_REPORT_LOGIN}${location.image_path}`
                    : location.image_base64
                }
                key={location.id}
                lat={parseFloat(location.lat)}
                lng={parseFloat(location.lng)}
                zoomLevel={self.state.map.getZoom()}
              ></IconMarker>
            );

            bounds.extend(
              new self.state.maps.LatLng(
                parseFloat(location.lat),
                parseFloat(location.lng)
              )
            );
          });
          if (self.props.auth.profile.map_type == "google") {
          } else {
            let temp = [];
            self.state.map.removeObjects(this.state.placeMarker);
            placeMarker &&
              placeMarker.map((ele) => {
                let htmlMarker = renderToString(ele);
                htmlMarker = `<div class="tooltip-marker">
                  <span class="tooltiptext-marker">${ele.props.title}</span>${htmlMarker}</div>`;
                var icon = new self.state.maps.map.DomIcon(htmlMarker, {
                  // the function is called every time marker enters the viewport
                }),
                  coords = { lat: ele.props.lat, lng: ele.props.lng },
                  marker = new self.state.maps.map.DomMarker(coords, {
                    icon: icon,
                  });

                if (typeof ele.props.onClick != "undefined") {
                  new self.state.maps.map.Group({
                    objects: [marker],
                  });
                }

                // Add the marker to the map:
                self.state.map.addObject(marker);
                temp.push(marker);
              });

            placeMarker = temp;
          }

          self.setState({
            placeMarker: placeMarker,
          });

          if (placeMarker.length > 0) {
            self.state.map.fitBounds(bounds);
          } else {
            message.error("ไม่พบสถานที่ในระบบ");
          }
        })
        .then(() => self.setState({ locationLoading: false }));
    }
  };

  reCalMarker = (trackingList) => {
    this.createClusters(trackingList);

    let self = this;
    if (
      this.props.auth.profile.map_type == "here" &&
      typeof this.state.map != "undefined" &&
      this.state.map != null
    ) {
      setTimeout(function () {
        self.state.map.dispatchEvent("mapviewchangeend");
      }, 600);
    }
  };

  loadData = (intervalLoading, firstTime) => {
    this.props.loadTracking({
      companyID:
        this.state.company_id_from_menu_vehicle != undefined
          ? this.state.company_id_from_menu_vehicle
          : this.props.auth.profile.company_id,
      token: this.props.auth.accessToken,
      selectedTrackingColumns: this.props.auth.profile.config.menu.tracking
        .columns,
      searchName: handleFilter(this.searchRef.current ? this.searchRef.current.input.state.value : ''),
      deviceType: this.state.deviceType,
      vehicleStatus: this.state.vehicleStatus,
      engineStatus: this.state.engineStatus,
      speedRange: this.state.speedRange,
      timeRange: this.state.timeRange,
      dataTreeFilter: this.state.masterDataSelectvalue,
      vehicle_visibility:
        this.state.vehicle_id_from_menu_vehicle != undefined
          ? this.state.vehicle_id_from_menu_vehicle
          : this.props.auth.profile.vehicle_visibility,
      intervalLoading: intervalLoading,
      cb: (trackingList) => {
        this.reCalMarker(trackingList);
        this.updateSelectedVehicle(trackingList);
        if (
          this.state.singleLoadStatus &&
          this.props.match.params.companyID !== undefined
        ) {
          if (this.state.maps != null) {
            let bounds = new this.state.maps.LatLngBounds();
            let position = null;

            for (let i = 0; i < trackingList.length; i++) {
              position = new this.state.maps.LatLng(
                trackingList[i]["lat"],
                trackingList[i]["lng"]
              );
              bounds.extend(position);
            }

            this.state.map.fitBounds(bounds);
          }
          this.setState({
            singleLoadStatus: false,
          });
        }
      },
      previousState: this.props.monitoringState.trackingList,
      firstTime,
    });
    this.stampTime();
  };

  loadInitData = (intervalLoading) => {
    this.props.loadTrackingInitData({
      companyID:
        this.state.company_id_from_menu_vehicle != undefined
          ? this.state.company_id_from_menu_vehicle
          : this.props.auth.profile.company_id,
      token: this.props.auth.accessToken,
      selectedTrackingColumns: this.props.auth.profile.config.menu.tracking
        .columns,
      searchName: handleFilter(this.searchRef.current ? this.searchRef.current.input.state.value : ''),
      deviceType: this.state.deviceType,
      vehicleStatus: this.state.vehicleStatus,
      engineStatus: this.state.engineStatus,
      speedRange: this.state.speedRange,
      timeRange: this.state.timeRange,
      dataTreeFilter: this.state.masterDataSelectvalue,
      vehicle_visibility:
        this.state.vehicle_id_from_menu_vehicle != undefined
          ? this.state.vehicle_id_from_menu_vehicle
          : this.props.auth.profile.vehicle_visibility,
      intervalLoading: intervalLoading,
      cb: this.reCalMarker,
      previousState: this.props.monitoringState.trackingList,
      cbFailure: this.alertError,
    });
    this.stampTime();
  };

  alertError = (msg) => {
    message.error(`พาหนะ ${msg} เกิดข้อผิดพลาด`);
  };

  onRemoveLists = () => {
    this.props.removeLists();
  };

  componentDidMount() {
    // this.loadInitData();
    this.loadData("undefined", "componentdidmount");
    this.getDeviceType();

    this.props.loadVehicleTypeMasterData({
      companyID:
        this.state.company_id_from_menu_vehicle != undefined
          ? this.state.company_id_from_menu_vehicle
          : this.props.auth.profile.company_id,
      token: this.props.auth.accessToken,
      vehicle_visibility:
        this.state.vehicle_id_from_menu_vehicle != undefined
          ? this.state.vehicle_id_from_menu_vehicle
          : this.props.auth.profile.vehicle_visibility,
    });

    this.props.loadVehicleGroupMasterData({
      companyID:
        this.state.company_id_from_menu_vehicle != undefined
          ? this.state.company_id_from_menu_vehicle
          : this.props.auth.profile.company_id,
      token: this.props.auth.accessToken,
      vehicle_visibility:
        this.state.vehicle_id_from_menu_vehicle != undefined
          ? this.state.vehicle_id_from_menu_vehicle
          : this.props.auth.profile.vehicle_visibility,
    });

    this.setState({
      panelWidth: document.getElementById("tracking-collapse").clientWidth,
    });

    //บริษัท สกายเวลล์ โหลดทุก 15 วินาที
    let setTime =
      parseInt(this.props.auth.profile.company_id) === 828 ? 15 : 30;
    this.trackingInterval = setInterval(
      () => this.loadData("undefined"),
      1000 * setTime
    );

    this.NotificationTrackingInterval = setInterval(
      () => this.fetchNotification(),
      1000 * 15
    );

    // setup fcm if messaging is not null
    // if (messaging) {
    //   messaging
    //     .getToken()
    //     .then((token) => {
    //       this.setToken(token);
    //       this.fetchNotification();
    //     })
    //     .catch((err) => {
    //       console.log("Unable to retrieve refreshed token ", err);
    //     });

    //   navigator.serviceWorker.addEventListener("message", ({ data }) => {
    //     let notification = {
    //       ...data.firebaseMessaging,
    //     };
    //     this.setNotifications(notification);
    //   });
    // }
    this.getJsession();

    // ดึงข้อมูล location type
    this.getLocationTypeTracking();

    // ดึงข้อมูล marker title display
    this.handleGetDisplayTitleMarker();
  }

  getDeviceType = () => {
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getDeviceTypeByVehicleId`,
        {
          vehicle_visibility: this.props.auth.profile.vehicle_visibility,
          company_id: this.props.auth.profile.company_id,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then((response) => {
        this.setState({ deviceTypeList: response.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  getJsession = () => {
    fetch(`${process.env.REACT_APP_API_MDVR}`)
      .then((res) => res.json())
      .then(
        (response) => {
          this.setState({
            jsessionid: response.jsession,
          });
        },

        (error) => {
          console.log(error);
        }
      );
  };

  fetchNotification() {
    const username = this.props.profile.username;
    const app = "appv2";
    const channel = "web_push_notification";

    axios
      .get(
        `${process.env.REACT_APP_NOTIFICATION_CENTER_TRACKING}/notifications`,
        {
          params: {
            username: `${username}`,
            app: "appv2",
            channel: "web_push_notification",
            limit: "20",
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          this.setNotificationsFormFetchNotification(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  setToken(token) {
    this.setState({
      ...this.state,
      token,
    });
  }

  setNotificationsFormFetchNotification = (data) => {
    let val = [];

    data.map((value) => {
      if (
        /^[\],:{}\s]*$/.test(
          value.message
            .replace(/\\["\\\/bfnrtu]/g, "@")
            .replace(
              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
              "]"
            )
            .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
        )
      ) {
        //the json is ok
        let myObject = JSON.parse(value.message);

        if (
          /^[\],:{}\s]*$/.test(
            myObject.json
              .replace(/\\["\\\/bfnrtu]/g, "@")
              .replace(
                /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                "]"
              )
              .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
          )
        ) {
          let json = JSON.parse(myObject.json);

          let start_at = moment(json.start_at)
            .add(7, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          let end_at = moment(json.end_at)
            .add(7, "hours")
            .format("YYYY-MM-DD HH:mm:ss");

          let date_now = moment().format("YYYY-MM-DD HH:mm:ss");
          let date_start = moment(start_at).format("DD/MM/YYYY");
          let date_end = moment(end_at).format("DD/MM/YYYY");

          let event_date_time = "";

          if (date_start == date_end) {
            // เกินขึ้น เเละ จบในวันวันเดียวกัน
            event_date_time =
              date_start +
              " " +
              moment(json.start_at)
                .add(7, "hours")
                .format("HH:mm:ss") +
              " - " +
              moment(json.end_at)
                .add(7, "hours")
                .format("HH:mm:ss");
          } else {
            // เกินขึ้น เเละ จบในวันถัดไป

            event_date_time =
              moment(start_at).format("DD/MM/YYYY HH:mm:ss") +
              " - " +
              moment(end_at).format("DD/MM/YYYY HH:mm:ss");
          }

          let ms = moment(date_now, "YYYY-MM-DD HH:mm:ss").diff(
            moment(end_at, "YYYY-MM-DD HH:mm:ss")
          );
          let d = moment.duration(ms);

          let last_day = parseInt(d.asDays());
          let last_hourt = parseInt(d.asHours());
          let last_min = parseInt(d.asMinutes());

          val.push({
            key: value.id,
            is_read: value.is_read,
            title: myObject.title,
            body: myObject.body,
            start_at: start_at,
            end_at: end_at,
            last_day: last_day,
            last_hourt: last_hourt,
            last_min: last_min,
            lat: json.lat,
            lng: json.lng,
            vehicles_id: json.vehicles_id,
            event_date_time: event_date_time,
            link: myObject.link,
          });
        }
      }
    });

    let dat = val.sort((a, b) => b.key - a.key);

    this.setState({
      data: dat,
    });
  };

  setNotifications(notification) {
    this.handleAddDataSourceFromApi(notification.payload.notification);

    this.setState({
      ...this.state,
      notifications: [...this.state.notifications, notification],
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  stampTime() {
    this.setState({
      curTime: moment().format("HH:mm"),
    });
  }

  componentWillUnmount() {
    clearInterval(this.trackingInterval);
    clearInterval(this.NotificationTrackingInterval);
    this.onRemoveLists();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      typeof document.getElementById("tracking-collapse").clientWidth !==
      undefined &&
      this.state.panelWidth !=
      document.getElementById("tracking-collapse").clientWidth
    ) {
      this.setState({
        panelWidth: document.getElementById("tracking-collapse").clientWidth,
      });
    }

    if (nextState.splitterLayoutHNT != this.state.splitterLayoutHNT) {
      return true;
    }
    if (
      nextState.splitterLayoutCustomTable !=
      this.state.splitterLayoutCustomTable
    ) {
      return true;
    }
    return true;
  }

  updateSelectedVehicle = (trackingList) => {
    if (this.state.selectedVehicle.plate_no != "") {
      if (trackingList.length > 0)
        this.handleVehicleSelectedChange(
          true,
          trackingList,
          this.state.selectedVehicle.plate_no
        );
    }
  };

  handleGetdistanceList = (value) => {
    let self = this;
    let features = [];
    let distance = value.data.distance;
    let interval = value.data.interval;
    value.features.map((item) => {
      item.geometry.coordinates.map((coordinatesItem) => {
        features = [
          ...features,
          { lat: coordinatesItem[1], lng: coordinatesItem[0] },
        ];
      });
    });
    if (this.props.auth.profile.map_type == "here") {
      let lineString = new this.state.maps.geo.LineString();

      for (let i = 0; i < features.length; i++) {
        lineString.pushPoint({ lat: features[i].lat, lng: features[i].lng });
      }

      let routeOutline = new this.state.maps.map.Polyline(lineString, {
        style: {
          lineWidth: 7,
          strokeColor: "rgba(0, 128, 255, 0.6)",
          lineTailCap: "arrow-tail",
          lineHeadCap: "arrow-head",
        },
      });
      let routeLine = new this.state.maps.map.Group();
      routeLine.addObjects([routeOutline]);

      if (this.state.routePolyline == null) {
        this.setState({ routePolyline: routeLine });

        this.state.map.addObjects([routeLine]);
      } else {
        this.state.map.removeObjects([this.state.routePolyline]);
        this.setState({ routePolyline: routeLine });
        this.state.map.addObjects([routeLine]);
      }
    }
    if (this.props.auth.profile.map_type == "google") {
      let routePolyline = new self.state.maps.Polyline({
        path: features,
        strokeColor: "#2E9AFE",
        strokeOpacity: 0.7,
        strokeWeight: 5,
      });

      if (this.state.routePolyline == null) {
        self.setState({ routePolyline: routePolyline });
        routePolyline.setMap(self.state.map);
      } else {
        this.state.routePolyline.setMap(null);
        self.setState({ routePolyline: routePolyline });
        routePolyline.setMap(self.state.map);
      }

      if (this.state.maps != null) {
        let bounds = new this.state.maps.LatLngBounds();
        let position = null;

        for (let i = 0; i < features.length; i++) {
          position = new this.state.maps.LatLng(
            features[i].lat,
            features[i].lng
          );
          bounds.extend(position);
        }

        this.state.map.fitBounds(bounds);
      }
    }
    let hours = Math.floor(interval / 3600); // get hours
    let minutes = Math.floor((interval - hours * 3600) / 60); // get minutes

    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;

    self.setState({
      distance: Number(distance / 1000).toFixed(2),
      intervalHours: hours,
      intervalMins: minutes,
      descriptionBoxHeader: `ระยะทาง : ${Number(distance / 1000).toFixed(
        2
      )} Km.`,
    });
  };

  handleAddDataSourceFromApi = (data) => {
    let start_at = moment(data.data.start_at)
      .add(7, "hours")
      .format("YYYY-MM-DD HH:mm:ss");
    let end_at = moment(data.data.end_at)
      .add(7, "hours")
      .format("YYYY-MM-DD HH:mm:ss");

    let date_now = moment().format("YYYY-MM-DD HH:mm:ss");
    let date_start = moment(start_at).format("DD/MM/YYYY");
    let date_end = moment(end_at).format("DD/MM/YYYY");

    let event_date_time = "";

    if (date_start == date_end) {
      // เกินขึ้น เเละ จบในวันวันเดียวกัน
      event_date_time =
        date_start +
        " " +
        moment(data.data.start_at)
          .add(7, "hours")
          .format("HH:mm:ss") +
        " - " +
        moment(data.data.end_at)
          .add(7, "hours")
          .format("HH:mm:ss");
    } else {
      // เกินขึ้น เเละ จบในวันถัดไป

      event_date_time =
        moment(start_at).format("DD/MM/YYYY HH:mm:ss") +
        " - " +
        moment(end_at).format("DD/MM/YYYY HH:mm:ss");
    }

    let ms = moment(date_now, "YYYY-MM-DD HH:mm:ss").diff(
      moment(end_at, "YYYY-MM-DD HH:mm:ss")
    );
    let d = moment.duration(ms);

    let last_day = parseInt(d.asDays());
    let last_hourt = parseInt(d.asHours());
    let last_min = parseInt(d.asMinutes());

    let value = [
      {
        key: data.data.notification_id,
        is_read: false,
        title: data.title,
        body: data.body,
        start_at: start_at,
        end_at: end_at,
        last_day: last_day,
        last_hourt: last_hourt,
        last_min: last_min,
        lat: data.data.lat,
        lng: data.data.lng,
        vehicles_id: data.data.vehicles_id,
        event_date_time: event_date_time,
        link: "",
      },
    ].concat(this.state.data);

    this.setState({
      data: value,
    });
  };

  handelIsRead = (selectedRowKeys, selectedRows) => {
    axios
      .put(
        `${process.env.REACT_APP_NOTIFICATION_CENTER_TRACKING}/notifications/${selectedRowKeys.key}`
      )
      .then((response) => {
        this.fetchNotification();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  onResize = (event, { element, size, handle }) => {
    this.setState({ widthResize: size.width, heightRezise: size.height });
  };

  setMapBound = (value) => {
    this.setState(
      { mapOptions: { ...this.state.mapOptions, ...value } },
      () => {
        this.createClusters(this.props.monitoringState.trackingList);
      }
    );
  };

  getLink = (imei) => {
    if (typeof imei === "undefined" || imei === "") return "honeytoast.php";
    let rec = this.props.honeytoaststream.vehicleListHoneytoast.filter(
      (x) => x.id === imei
    );
    if (rec.length > 0) {
      return rec[0].target_link;
    }
    return "honeytoast.php";
  };

  loadListVehicleHoneytoast = (values, loading) => {
    this.props.loadListVehicleHoneytoast(values, loading);
  };

  setLocationTypeList = (value) => {
    this.setState(
      {
        locationTypeList: value,
        filter_location_type: value.map((item) => item) || [],
        filter_location_type_indeterminate: false,
        filter_location_type_checkAll: true,
      },
      () => { }
    );
  };

  // *เมื่อทำการ Filter เลือก ALL หรือ Uncheck ALL
  onCheckAllFilterLocationType = (checked) => {
    let self = this;
    const { locationTypeList } = this.state;
    this.setState(
      {
        filter_location_type: checked
          ? locationTypeList.map((item) => item)
          : [],
        filter_location_type_indeterminate: false,
        filter_location_type_checkAll: checked,
        isFilterLocationTypeClick: checked ? true : false,
      },
      () => {
        if (checked) {
          this.clearLocationMarker(false);
          this.generateLocationMarker(self);
        } else {
          this.clearLocationMarker(true);
        }
      }
    );
  };

  // *เมื่อทำการ Filter เลือก location type แต่ละอัน
  onCheckFilterLocationType = (checked, value) => {
    let self = this;
    let { locationTypeList, filter_location_type } = this.state;

    let temp = [];
    if (checked) {
      temp = [...filter_location_type, value];
    } else {
      temp = filter_location_type.filter((item) => item != value);
    }

    this.setState(
      {
        filter_location_type: temp,
        filter_location_type_indeterminate:
          !!temp.length && temp.length < locationTypeList.length,
        filter_location_type_checkAll: temp.length === locationTypeList.length,
        isFilterLocationTypeClick: temp.length > 0 ? true : false,
      },
      () => {
        if (temp.length > 0) {
          this.clearLocationMarker(false);
          this.generateLocationMarker(self);
        } else {
          this.clearLocationMarker(true);
        }
      }
    );
  };

  // *คลิกเปิดปิด Filter Location Type
  handleChangeVisibleFilterLocationType = (disabled, visible) => {
    let self = this;
    const { firstSearchLocationType } = this.state;

    if (!disabled) {
      if (firstSearchLocationType) {
        this.setState(
          {
            visibleFilterLocationType: visible,
            firstSearchLocationType: false,
            isFilterLocationTypeClick: true,
          },
          () => {
            this.clearLocationMarker(false);
            this.generateLocationMarker(self);
          }
        );
      } else {
        this.setState({
          visibleFilterLocationType: visible,
        });
      }
    } else {
      this.setState({ visibleFilterLocationType: false });
    }
  };

  // *เมื่อคลิกshow location name
  onCheckShowLocationName = (checked) => {
    const { locationInfoMarkerLists } = this.state;
    let temp = locationInfoMarkerLists.map((item) => {
      return {
        ...item,
        props: {
          ...item.props,
          alwaysShowTooltip: checked,
        },
      };
    });

    this.setState(
      {
        showLocationName: checked,
        locationInfoMarkerLists: temp,
      },
      () => { }
    );
  };

  getLocationTypeTracking = () => {
    let self = this;
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationtypetracking`,
        {
          company_id: this.props.auth.profile.company_id,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then((response) => {
        self.setLocationTypeList(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  getVehicleMarkerTitle = (value) => {
    if (this.state.displayTitleMarker.length == 2) {
      let title = `<p class="tracking-marker">${value.plate_no}</p><p class="tracking-marker">${value.vehicle_code}</p>`;
      let titleLength =
        value.vehicle_code.length > value.plate_no.length
          ? value.vehicle_code
          : value.plate_no;

      return {
        title: title,
        check_length: titleLength,
      };
    }

    if (this.state.displayTitleMarker.includes("plateNo")) {
      return {
        title: value.plate_no,
        check_length: value.plate_no,
      };
    }

    if (this.state.displayTitleMarker.includes("vehicleCode")) {
      return {
        title: value.vehicle_code,
        check_length: value.vehicle_code,
      };
    }
  };

  handleChooseVehicleMarkerTitile = (check, value) => {
    let result = [];
    if (!check) {
      result = this.state.displayTitleMarker.filter((x) => x != value);
    } else {
      result = [...this.state.displayTitleMarker, value];
    }

    if (result.length == 0) {
      result.push("plateNo");
    }

    this.setState(
      {
        displayTitleMarker: result,
      },
      () => {
        // TODO: save to localstorage
        if (this.props.profile != null) {
          let newProfile = {
            ...this.props.profile,
            permissions: JSON.stringify(this.props.profile.permissions),
            config: JSON.stringify({
              ...this.props.profile.config,
              menu: {
                ...this.props.profile.config.menu,
                tracking: {
                  ...this.props.profile.config.menu.tracking,
                  marker_title_display: result,
                },
              },
            }),
          };

          localStorage.setItem("profile", JSON.stringify(newProfile));
        }
      }
    );

    // TODO:save to db
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateuserconfigtrackingcolumn`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          user_id: this.props.profile.id,
          marker_title_display: JSON.stringify(result),
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => { });
  };

  handleGetDisplayTitleMarker = () => {
    // get data from localstorage
    const { displayTitleMarkerCheckList } = this.state;
    let result = ["plateNo"];
    let profile = JSON.parse(localStorage.getItem("profile"));
    if (profile != null) {
      let config = JSON.parse(profile.config);
      if (config.menu.tracking.marker_title_display != undefined) {
        let marker_title_display =
          config.menu.tracking.marker_title_display || [];

        if (marker_title_display.length > 0) {
          result = marker_title_display;
        } else {
          result = ["plateNo"];
        }

        // ถ้ามีนอกเหนือจาก choice ปัจจุบันให้ลบออก
        let allowedValues = displayTitleMarkerCheckList.map(
          (item) => item.check_value
        );
        result = result.filter((value) => allowedValues.includes(value));
      }
    }

    this.setState(
      {
        displayTitleMarker: result,
      },
      () => { }
    );
  };

  handleFindThisArea = () => {
    clearInterval(this.trackingInterval);
    const self = this;

    this.props.loadTrackingInitData({
      companyID: this.props.auth.profile.company_id,
      token: this.props.auth.accessToken,
      selectedTrackingColumns: this.props.auth.profile.config.menu.tracking
        .columns,
      searchName: handleFilter(this.searchRef.current ? this.searchRef.current.input.state.value : ''),
      deviceType: this.state.deviceType,
      vehicleStatus: this.state.vehicleStatus,
      engineStatus: this.state.engineStatus,
      speedRange: this.state.speedRange,
      timeRange: this.state.timeRange,
      dataTreeFilter: this.state.masterDataSelectvalue,
      vehicle_visibility:
        this.state.vehiclesInMarker.length != 0
          ? this.state.vehiclesInMarker.toString()
          : "",
      cb: (markers) => {
        typeof markers != "undefined" && self.reCalMarker(markers);
        self.handleFilterCancel();
      },
      previousState: this.props.monitoringState.trackingList,
      cbFailure: this.alertError,
    });

    let setTime =
      parseInt(this.props.auth.profile.company_id) === 828 ? 15 : 30;

    this.trackingInterval = setInterval(
      () =>
        this.props.loadTracking({
          companyID:
            this.state.company_id_from_menu_vehicle != undefined
              ? this.state.company_id_from_menu_vehicle
              : this.props.auth.profile.company_id,
          token: this.props.auth.accessToken,
          selectedTrackingColumns: this.props.auth.profile.config.menu.tracking
            .columns,
          searchName: handleFilter(this.searchRef.current ? this.searchRef.current.input.state.value : ''),
          deviceType: this.state.deviceType,
          vehicleStatus: this.state.vehicleStatus,
          engineStatus: this.state.engineStatus,
          speedRange: this.state.speedRange,
          timeRange: this.state.timeRange,
          dataTreeFilter: this.state.masterDataSelectvalue,
          vehicle_visibility:
            this.state.vehiclesInMarker.length != 0
              ? this.state.vehiclesInMarker.toString()
              : "",
          intervalLoading: "undefined",
          cb: (trackingList) => {
            this.reCalMarker(trackingList);
            this.updateSelectedVehicle(trackingList);
          },
          previousState: this.props.monitoringState.trackingList,
          firstTime: false,
        }),
      1000 * setTime
    );
  };

  render() {
    let iframe = "";
    let getReduxHNT = this.props.monitoringState.vehicleListHoneytoast;
    iframe =
      getReduxHNT.length > 0 && this.state.splitterLayoutCustomTable ? (
        <Row>
          <Col span={24} style={{ marginTop: "12px" }}>
            <Spin spinning={this.state.iframeLoading1}>
              <Row
                justify="center"
                style={{
                  position: "absolute",
                  margin: "0px 0px 15px 0px",
                  bottom: "305px",
                  right: "2%",
                }}
              >
                <Col
                  span={24}
                  align="center"
                  style={{
                    color: "white",
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    onClick={() => {
                      this.setState({
                        splitterLayoutCustomTable: false,
                        splitterLayoutHNT: false,
                      });
                    }}
                  >
                    x
                  </div>
                </Col>
              </Row>
              <Iframe
                url={
                  this.state.url +
                  getReduxHNT[0].target_link +
                  "?imei=" +
                  getReduxHNT[0].hardware_id +
                  "&plate_no=" +
                  getReduxHNT[0].plate_no +
                  "&user_id=" +
                  this.props.profile.id.toString()
                }
                onLoad={this.hideSpinner1}
                width="100%"
                height={360 + "px"}
                className="iframe-tracking-style"
              />
            </Spin>
          </Col>
        </Row>
      ) : (
        ""
      );

    const mapTools = (
      <Menu>
        <Menu.Item
          key="3"
          onClick={this.handlePinPointOfInterest}
          style={{
            color:
              this.state.isMenuMapToolsCLick === "pushpin"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <Icon type="pushpin" />
          <LangContext.Consumer>
            {(i18n) => i18n.p.pinDown}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={this.handleFindDistance}
          style={{
            color:
              this.state.isMenuMapToolsCLick === "findDistance"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <Icon type="highlight" />
          <LangContext.Consumer>
            {(i18n) => i18n.f.findDistance}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item
          key="5"
          onClick={this.handleFindArea}
          style={{
            color:
              this.state.isMenuMapToolsCLick === "findArea"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <Icon type="border" />
          <LangContext.Consumer>
            {(i18n) => i18n.f.findSpace}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item key="6" onClick={this.handleClearAllToolDrawing}>
          <Icon type="close" />
          <LangContext.Consumer>
            {(i18n) => i18n.c.clearUp}
          </LangContext.Consumer>
        </Menu.Item>
      </Menu>
    );

    const mapType = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={this.handleMapTypeSatellite}
          style={{
            color:
              this.state.isMenuMapTypeClick === "satellite"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <LangContext.Consumer>
            {(i18n) => i18n.s.satellite}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={this.handleMapTypeTerrain}
          style={{
            color:
              this.state.isMenuMapTypeClick === "terrain"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <LangContext.Consumer>{(i18n) => i18n.m.map}</LangContext.Consumer>
        </Menu.Item>
      </Menu>
    );

    const { trackingList } = this.props.monitoringState;
    let filterData = [
      this.initVehicleTypeMasterDataFilter(
        this.props.monitoringState.vehicleTypeMasterDataTreeFilter
      ),
      this.initVehicleGroupMasterDataFilter(
        this.props.monitoringState.vehicleGroupMasterDataTreeFilter
      ),
    ];
    let masterDataTreeProps = {
      treeData: filterData,
      value: this.state.masterDataSelectvalue,
      onChange: this.handleMasterDataTreeChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      searchPlaceholder: "ข้อมูลหลัก",
      style: {
        width: "100%",
      },
    };

    const zoomLevelUpToShowLocation =
      this.state.map !== null &&
      this.state.map.zoom > mapMinimumZoomToShowGroupByLocation;

    const loadingMdvrModal = (loading) => {
      this.setState({
        loadingMdvr: loading,
      });
    };

    const tabsIconUser = (
      <span style={{ fontSize: "0.875rem" }}>
        <Icon type="user" />{" "}
        <LangContext.Consumer>
          {(i18n) => i18n.c.chauffeurDriver}
        </LangContext.Consumer>
      </span>
    );

    const tabsIconCarRegistration = (
      <span style={{ fontSize: "0.875rem" }}>
        <Icon type="car" />{" "}
        <LangContext.Consumer>{(i18n) => i18n.p.plateNo}</LangContext.Consumer>
      </span>
    );

    let config = this.props.profile.config;
    let {
      menu: {
        tracking: { columns },
      },
    } = config;
    return (
      <AuthorizeComponent matching_name="tracking_dashboard">
        <TrackingStyledContent>
          <Spin spinning={this.props.monitoringState.trackingLoading}>
            <Row>
              <Col
                sm={24}
                md={this.state.tablePanelClose ? 0 : 9}
                lg={this.state.tablePanelClose ? 0 : 9}
                xl={this.state.tablePanelClose ? 0 : 9}
                style={{
                  margin: "0px 0px 0px 0px",
                  padding: "5px 10px 5px 10px",
                }}
              >
                <div id="tracking-collapse">
                  <Col
                    span={24}
                    style={{
                      margin: "0px 0px 5px 0px",
                      maxHeight: `${window.innerHeight - 120}px`,
                      paddingTop: "1%",
                    }}
                  >
                    <Radio.Group
                      onChange={this.onChangeTableLayout}
                      defaultValue="a"
                      className="radio-group-button-tracking-style"
                    >
                      <Row gutter={[4, 4]}>
                        <Col span={24}>
                          <Search
                            ref={this.searchRef}
                            placeholder="ค้นหาพาหนะหรือรหัสพาหนะ"
                            enterBonSearchutton="Search"
                            allowClear={true}
                            onSearch={(val) => {
                              this.setState({
                                hasSearchCriteria: val == "" ? false : true,
                                searchName: val
                              }, () => {
                                this.handleApplayButtonClick(val)
                              });
                            }
                            }
                          />
                        </Col>
                        <Col
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={this.state.hasSearchCriteria ? 6 : 7}
                          xxl={this.state.hasSearchCriteria ? 6 : 8}
                        >
                          <Radio.Button
                            value="a"
                            className="radio-button-tracking-style"
                          >
                            {tabsIconCarRegistration}
                          </Radio.Button>
                        </Col>
                        <Col
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={this.state.hasSearchCriteria ? 6 : 7}
                          xxl={this.state.hasSearchCriteria ? 6 : 8}
                        >
                          <Radio.Button
                            value="b"
                            className="radio-button-tracking-style"
                          >
                            {tabsIconUser}
                          </Radio.Button>
                        </Col>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={this.state.hasSearchCriteria ? 12 : 10}
                          xxl={this.state.hasSearchCriteria ? 12 : 8}
                        >
                          <Row gutter={[12, 0]}>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={this.state.hasSearchCriteria ? 8 : 12}
                              xxl={this.state.hasSearchCriteria ? 8 : 12}
                              align="center"
                            >
                              <Button
                                className="edge-element-tracking"
                                style={{ margin: "0px 0px 5px 0px" }}
                                icon="setting"
                                onClick={this.showConfigModal}
                                block
                              >
                                {" "}
                                <LangContext.Consumer>
                                  {(i18n) => i18n.s.setting}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={this.state.hasSearchCriteria ? 8 : 12}
                              xxl={this.state.hasSearchCriteria ? 8 : 12}
                              align="center"
                            >
                              <Tooltip
                                placement="right"
                                title={
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.o.openFilter}
                                  </LangContext.Consumer>
                                }
                              >
                                <Button
                                  className="edge-element-tracking"
                                  style={{ margin: "0px 0px 5px 0px" }}
                                  icon="filter"
                                  onClick={this.showFilterModal}
                                  htmlType="submit"
                                  block
                                >
                                  {" "}
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.f.filter}
                                  </LangContext.Consumer>
                                </Button>
                              </Tooltip>

                              <FilterModal
                                {...this.props}
                                DEFAULT_FILTER_VALUE={DEFAULT_FILTER_VALUE}
                                filterVisible={this.state.filterVisible}
                                handleApplayButtonClick={
                                  this.handleApplayButtonClick
                                }
                                handleResetButtonClick={
                                  this.handleResetButtonClick
                                }
                                handleFilterCancel={this.handleFilterCancel}
                                masterDataTreeProps={masterDataTreeProps}

                                handleDeviceTypeChange={
                                  this.handleDeviceTypeChange
                                }
                                handleMasterDataTreeUnfocus={
                                  this.handleMasterDataTreeUnfocus
                                }
                                handleVehicleStatusSelectboxChange={
                                  this.handleVehicleStatusSelectboxChange
                                }
                                handleSpeedRangeChange={
                                  this.handleSpeedRangeChange
                                }
                                handleTimeRangeChange={
                                  this.handleTimeRangeChange
                                }
                                deviceType={this.state.deviceType}
                                deviceTypeList={this.state.deviceTypeList}
                                searchName={this.searchRef.current ? this.searchRef.current.input.state.value : ''}
                                vehicleStatus={this.state.vehicleStatus}
                                engineStatus={this.state.engineStatus}
                                speedRange={this.state.speedRange}
                                timeRange={this.state.timeRange}
                                masterDataSelectvalue={
                                  this.state.masterDataSelectvalue
                                }
                                division={this.state.division}
                                department={this.state.department}
                                section={this.state.section}
                                plant={this.state.plant}
                                vendor={this.state.vendor}
                                handleDivisionChange={this.handleDivisionChange}
                                handleDepartmentChange={
                                  this.handleDepartmentChange
                                }
                                handleSectionChange={this.handleSectionChange}
                                handlePlantChange={this.handlePlantChange}
                                handleVendorChange={this.handleVendorChange}
                                selectedDivision={this.state.selectedDivision}
                                selectedDepartment={
                                  this.state.selectedDepartment
                                }
                                selectedSection={this.state.selectedSection}
                                selectedPlant={this.state.selectedPlant}
                                selectedVendor={this.state.selectedVendor}
                                handleUserRecentInitFilter={
                                  this.handleUserRecentInitFilter
                                }
                              />
                              <ConfigModal
                                {...this.props}
                                onLatestConfigChange={this.onLatestConfigChange}
                                latest_config_change={
                                  this.state.latest_config_change
                                }
                                loadData={this.loadData}
                                profile={this.props.auth.profile}
                                allCol={allTrackingColumns}
                                configVisible={this.state.configVisible}
                                handleConfigCancel={this.handleConfigCancel}
                              />
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={this.state.hasSearchCriteria ? 8 : 12}
                              xxl={this.state.hasSearchCriteria ? 8 : 12}
                              align="center"
                            >
                              <Button
                                className="edge-element-tracking"
                                style={{
                                  margin: "0px 5px 5px 0px",
                                  display: this.state.hasSearchCriteria
                                    ? "inline"
                                    : "none",
                                }}
                                icon="close"
                                onClick={this.handleResetButtonClick}
                                block
                              >
                                {" "}
                                <LangContext.Consumer>
                                  {(i18n) => i18n.c.clear}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          span={24}
                          style={{
                            margin: "0px 0px 5px 0px",
                            maxHeight: `${window.innerHeight - 110}px`,
                          }}
                        >
                          <div
                            style={{ height: `${window.innerHeight - 110}px` }}
                          >
                            {this.state.eleSplitterTableLayout ? (
                              <SplitterLayout primaryIndex={0} vertical={true}>
                                <div>
                                  <CustomTable
                                    auth={this.props.auth}
                                    changeConfigTrackingColumns={
                                      this.props.changeConfigTrackingColumns
                                    }
                                    onLatestConfigChange={
                                      this.onLatestConfigChange
                                    }
                                    latest_config_change={
                                      this.state.latest_config_change
                                    }
                                    allCol={allTrackingColumns}
                                    profile={this.props.auth.profile}
                                    openNav={this.openNav}
                                    loadedDateTime={
                                      this.props.monitoringState.loadedDateTime
                                    }
                                    selectedVehicle={this.state.selectedVehicle}
                                    trackingList={trackingList.filter((x) => {
                                      if (
                                        this.state.setDataTable.length === 0
                                      ) {
                                        return x;
                                      }
                                      return this.state.setDataTable.includes(
                                        x.vehicle_id
                                      );
                                    })}
                                    openInfoPanel={this.openInfoPanel}
                                    showLocationLayer={
                                      this.state.showLocationLayer
                                    }
                                    splitterLayoutHNT={
                                      this.state.splitterLayoutHNT
                                    }
                                    switchCustomizeTable={
                                      this.state.switchCustomizeTable
                                    }
                                    handleChangeCustomizeTable={
                                      this.handleChangeCustomizeTable
                                    }
                                    getDragColumn={this.getDragColumn}
                                  />
                                  {this.state.splitterLayoutHNT ? iframe : ""}
                                </div>
                              </SplitterLayout>
                            ) : (
                              <SplitterLayout primaryIndex={0}>
                                <div>
                                  <CustomTableTrackingCarRegis
                                    auth={this.props.auth}
                                    onLatestConfigChange={
                                      this.onLatestConfigChange
                                    }
                                    latest_config_change={
                                      this.state.latest_config_change
                                    }
                                    allCol={allTrackingColumns}
                                    profile={this.props.auth.profile}
                                    openNav={this.openNav}
                                    loadedDateTime={
                                      this.props.monitoringState.loadedDateTime
                                    }
                                    selectedVehicle={this.state.selectedVehicle}
                                    trackingList={trackingList.filter((x) => {
                                      if (
                                        this.state.setDataTable.length === 0
                                      ) {
                                        return x;
                                      }

                                      return this.state.setDataTable.includes(
                                        x.vehicle_id
                                      );
                                    })}
                                    openInfoPanel={this.openInfoPanel}
                                    showLocationLayer={
                                      this.state.showLocationLayer
                                    }
                                    handleChangeCustomizeTable={
                                      this.handleChangeCustomizeTable
                                    }
                                    switchCustomizeTable={
                                      this.state.switchCustomizeTable
                                    }
                                    splitterLayoutHNT={
                                      this.state.splitterLayoutHNT
                                    }
                                  />
                                  {this.state.splitterLayoutHNT ? iframe : ""}
                                </div>
                              </SplitterLayout>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Col>
                  {this.state.vehicle_id_from_menu_vehicle != undefined ? (
                    <Button
                      className="edge-element"
                      style={{ margin: "0px 0px 5px 0px" }}
                      size="small"
                      onClick={this.goBack}
                    >
                      {" "}
                      <LangContext.Consumer>
                        {(i18n) => i18n.b.back}
                      </LangContext.Consumer>
                    </Button>
                  ) : (
                    ""
                  )}
                  <PopupDrawerNew
                    title="พาหนะ"
                    placement="bottom"
                    closable={false}
                    onClose={this.closeNav}
                    visible={this.state.visibleDrawer}
                    permissions={this.props.auth.profile.permissions}
                    width={this.state.width}
                    panelWidth={this.state.panelWidth}
                    closeNav={this.closeNav}
                    selectedVehicle={this.state.selectedVehicle}
                    loadListVehicleMdvr={this.props.loadListVehicleMdvr}
                    companyId={this.props.auth.profile.company_id}
                    loadingMdvrModal={loadingMdvrModal}
                    jsessionid={this.state.jsessionid}
                    columns={columns}
                    handleShowHNTLiveStream={this.handleShowHNTLiveStream}
                    loadListVehicleHoneytoast={this.loadListVehicleHoneytoast}
                    handleVehicleSelectedChange={
                      this.handleVehicleSelectedChange
                    }
                    trackingList={this.props.monitoringState.trackingList}
                  />
                </div>
              </Col>

              <Col
                md={this.state.tablePanelClose ? 24 : 15}
                lg={this.state.tablePanelClose ? 24 : 15}
                xl={this.state.tablePanelClose ? 24 : 15}
              >
                <div
                  style={{
                    position: "absolute",
                    marginTop: "4px",
                    top: "7px",
                    right: "0px",
                    zIndex: "12",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <Row>
                    <Col
                      sm={24}
                      md={1}
                      lg={1}
                      xl={1}
                      style={{
                        paddingRight: this.state.tablePanelClose
                          ? "40px"
                          : "10px",
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={
                          <LangContext.Consumer>
                            {(i18n) =>
                              this.state.tablePanelClose
                                ? i18n.f.fullScreenExit
                                : i18n.f.fullScreen
                            }
                          </LangContext.Consumer>
                        }
                      >
                        <button
                          style={{
                            backgroundColor: this.state.tablePanelClose
                              ? "rgb(22, 146, 255)"
                              : "",
                            color: this.state.tablePanelClose
                              ? "rgb(255, 255, 255)"
                              : "",
                            cursor: "pointer",
                            height: 30,
                            width: 30,
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            this.setState(
                              {
                                tablePanelClose: !this.state.tablePanelClose,
                                showEditTable: !this.state.showEditTable,
                                switchCustomizeTable: false,
                              },
                              () => {
                                if (this.props.auth.profile.map_type == "here")
                                  this.state.map.getViewPort().resize();
                                if (
                                  this.props.auth.profile.map_type == "longdo"
                                )
                                  this.state.map.resize();
                                this.reCalMarker(
                                  this.props.monitoringState.trackingList
                                );
                              }
                            );
                          }}
                        >
                          <Icon
                            type={
                              this.state.tablePanelClose
                                ? "double-right"
                                : "double-left"
                            }
                          />
                        </button>
                      </Tooltip>
                    </Col>
                    <Col sm={24} md={11} lg={11} xl={11} align="left">
                      <LangContext.Consumer>
                        {(i18n) => (
                          <UniversalSearch
                            className="edge-element"
                            style={{
                              width: "110px",
                              margin: "0px 15px 0px 0px",
                            }}
                            placeholder={i18n.f.fillPosition}
                            auth={this.props.auth}
                            searchLocationType={this.state.searchLocationType}
                            handleSearchPlace={this.handleSearchPlace}
                            i18n={i18n}
                            companyID={this.props.auth.profile.company_id}
                            accessToken={this.props.auth.accessToken}
                            mapKey={this.props.auth.profile.longdo_map_key}
                            searchSource={this.state.searchSource}
                            handleSearchSourceChange={(v) =>
                              this.setState({ searchSource: v })
                            }
                            handleSearchLocationType={(v) =>
                              this.setState({ searchLocationType: v })
                            }
                            bounds={this.state.mapOptions.bounds}
                          ></UniversalSearch>
                        )}
                      </LangContext.Consumer>
                    </Col>

                    <Col sm={24} md={12} lg={12} xl={12}>
                      {this.state.map != null ? (
                        <Button
                          type={this.state.btnAllCar ? "primary" : ""}
                          onClick={this.handleListMode}
                          style={{ marginRight: 10 }}
                        >
                          <Icon type="car" style={{ fontSize: "larger" }} />{" "}
                          <LangContext.Consumer>
                            {(i18n) => i18n.a.allTruck}
                          </LangContext.Consumer>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <ButtonGroup
                        style={{
                          marginRight: "4px",
                        }}
                      >
                        <Dropdown className="edge-element" overlay={mapTools}>
                          <Button>
                            <LangContext.Consumer>
                              {(i18n) => i18n.t.tools}
                            </LangContext.Consumer>{" "}
                            <Icon type="down" />
                          </Button>
                        </Dropdown>
                        <Dropdown className="edge-element" overlay={mapType}>
                          <Button>
                            <LangContext.Consumer>
                              {(i18n) => i18n.m.mapView}
                            </LangContext.Consumer>{" "}
                            <Icon type="down" />
                          </Button>
                        </Dropdown>
                      </ButtonGroup>
                    </Col>
                    {this.state.showEditTable && (
                      <Col
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        style={{ marginTop: "30px", marginLeft: "5px" }}
                      >
                        <div style={{ textAlign: "left" }}>
                          <Switch
                            checkedChildren={
                              <LangContext.Consumer>
                                {(i18n) => i18n.e.editTable}
                              </LangContext.Consumer>
                            }
                            unCheckedChildren={
                              <LangContext.Consumer>
                                {(i18n) => i18n.e.editTable}
                              </LangContext.Consumer>
                            }
                            onChange={this.onChangeEditCustomizeTable}
                            checked={this.state.switchCustomizeTable}
                          />
                        </div>
                      </Col>
                    )}

                    {this.state.showBtnSaveTable ? (
                      <Col
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        style={{ marginLeft: 7, marginTop: 7 }}
                      >
                        <div
                          style={{ textAlign: "left", marginLeft: "0.5rem" }}
                        >
                          <Tooltip
                            placement="right"
                            title={
                              <LangContext.Consumer>
                                {(i18n) => i18n.s.saveTableSize}
                              </LangContext.Consumer>
                            }
                          >
                            <Button onClick={this.handleSaveCustomizeTable}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.s.save}
                              </LangContext.Consumer>
                            </Button>
                          </Tooltip>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <div
                    className="edge-btn-group"
                    style={{
                      position: "absolute",
                      top: 150,
                      right: "0px",
                      zIndex: "12",
                    }}
                  >
                    <MarkerTitleDisplay
                      styles={{
                        backgroundColor: this.state.showDisplayTitleFilter
                          ? "rgb(22, 146, 255)"
                          : "",
                        color: this.state.showDisplayTitleFilter
                          ? "rgb(255, 255, 255)"
                          : "",
                      }}
                      displayType={this.state.displayTitleMarker}
                      onCheck={this.handleChooseVehicleMarkerTitile}
                      visible={this.state.showDisplayTitleFilter}
                      handleChangeVisible={(value) => {
                        this.setState({ showDisplayTitleFilter: value });
                      }}
                      checkList={this.state.displayTitleMarkerCheckList}
                    />

                    <LocationTypeDropdown
                      classNames={
                        zoomLevelUpToShowLocation ? "" : "disabled-button"
                      }
                      styles={{
                        backgroundColor: this.state.isFilterLocationTypeClick
                          ? "rgb(22, 146, 255)"
                          : "",
                        color:
                          this.state.isFilterLocationTypeClick &&
                            zoomLevelUpToShowLocation
                            ? "rgb(255, 255, 255)"
                            : "",
                      }}
                      disabled={!zoomLevelUpToShowLocation}
                      locationTypeList={this.state.locationTypeList}
                      filter_location_type={this.state.filter_location_type}
                      isFilterLocationTypeClick={
                        this.state.isFilterLocationTypeClick
                      }
                      visibleFilterLocationType={
                        this.state.visibleFilterLocationType
                      }
                      indeterminate={
                        this.state.filter_location_type_indeterminate
                      }
                      checkAll={this.state.filter_location_type_checkAll}
                      onCheckAllFilterLocationType={
                        this.onCheckAllFilterLocationType
                      }
                      onCheckFilterLocationType={this.onCheckFilterLocationType}
                      handleChangeVisibleFilterLocationType={
                        this.handleChangeVisibleFilterLocationType
                      }
                      showLocationName={this.state.showLocationName}
                      onCheckShowLocationName={this.onCheckShowLocationName}
                    />
                    {/* !ของเดิมไม่ใช้ ปิดไว้ก่อน */}
                    {/* <Tooltip
                      placement='left'
                      title={
                        zoomLevelUpToShowLocation ? (
                          this.state.showLocationLayer ? (
                            <LangContext.Consumer>
                              {(i18n) => i18n.c.cancel}
                            </LangContext.Consumer>
                          ) : (
                            <LangContext.Consumer>
                              {(i18n) => i18n.g.groupByLocation}
                            </LangContext.Consumer>
                          )
                        ) : (
                          <LangContext.Consumer>
                            {(i18n) =>
                              i18n.z.zoomInForMoreInfo + i18n.g.groupByLocation
                            }
                          </LangContext.Consumer>
                        )
                      }
                    >
                      <button
                        className={
                          zoomLevelUpToShowLocation ? '' : 'disabled-button'
                        }
                        style={{
                          backgroundColor: this.state.isGroupByLocationClick
                            ? 'rgb(22, 146, 255)'
                            : '',
                          color:
                            this.state.isGroupByLocationClick &&
                            zoomLevelUpToShowLocation
                              ? 'rgb(255, 255, 255)'
                              : '',
                        }}
                        onClick={this.handleGroupVehicleByLocation}
                        disabled={zoomLevelUpToShowLocation ? false : true}
                      >
                          <Icon type='dot-chart' />
                      </button>
                    </Tooltip> */}

                    <Tooltip
                      placement="left"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.s.showTraffic}
                        </LangContext.Consumer>
                      }
                    >
                      <button
                        onClick={this.handleShowTrafficLayer}
                        style={{
                          backgroundColor: this.state.isShowTrafficClick
                            ? "rgb(22, 146, 255)"
                            : "",
                          color: this.state.isShowTrafficClick
                            ? "rgb(255, 255, 255)"
                            : "",
                        }}
                      >
                        <Icon type="swap" />
                      </button>
                    </Tooltip>
                    <Tooltip
                      placement="left"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.z.zoomIn}
                        </LangContext.Consumer>
                      }
                    >
                      <button onClick={this.handleZoomFocusIncrease}>
                        <Icon type="zoom-in" />
                      </button>
                    </Tooltip>
                    <Tooltip
                      placement="left"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.z.zoomOut}
                        </LangContext.Consumer>
                      }
                    >
                      <button onClick={this.handleZoomFocusDecrease}>
                        <Icon type="zoom-out" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <TrackingPinPoint
                  descriptionBoxDrawerWidth={
                    this.state.descriptionBoxDrawerWidth
                  }
                  handleDescriptionBoxDrawerClose={
                    this.handleDescriptionBoxDrawerClose
                  }
                  isMenuMapToolsCLick={this.state.isMenuMapToolsCLick}
                  showDistance={this.state.showDistance}
                  descriptionBoxHeader={this.state.descriptionBoxHeader}
                  descriptionBoxDetail={this.state.descriptionBoxDetail}
                  pinLat={this.state.pinLat}
                  pinLng={this.state.pinLng}
                  companyID={this.props.auth.profile.company_id}
                  vehicle_under_control={
                    this.props.auth.profile.vehicle_visibility
                  }
                  accessToken={this.props.auth.accessToken}
                  location_visibility={
                    this.props.auth.profile.location_visibility
                  }
                  permissions={this.props.auth.profile.permissions}
                  trackingList={trackingList}
                  handleGetdistanceList={this.handleGetdistanceList}
                  distance={this.state.distance}
                  intervalHours={this.state.intervalHours}
                  intervalMins={this.state.intervalMins}
                  onFindThisArea={this.handleFindThisArea}
                />

                {this.state.locationLoading ? (
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      marginTop: "70px",
                      top: "0px",
                      right: "50%",
                      zIndex: "12",
                      borderRadius: "6px",
                    }}
                  >
                    <Spin
                      style={{ margin: "15px 10px 10px 10px" }}
                      spinning={true}
                    ></Spin>
                    <span
                      style={{ margin: "0px 10px 0px 0px" }}
                      className="blink"
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.processing}
                      </LangContext.Consumer>
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {this.props.auth.profile.map_type == "here" && (
                  <HereMap
                    handleApiLoaded={this.handleApiLoaded}
                    setMapBound={this.setMapBound}
                    placeMarker={this.state.placeMarker}
                    marker={this.state.clusters.map((item, index) => {
                      if (item.numPoints === 1) {
                        let result = this.getVehicleMarkerTitle(
                          item.markerInfo
                        );
                        return (
                          <VehicleMarker
                            key={"vehicleMarker" + index}
                            title={result.title}
                            checkLengthTitle={result.check_length}
                            vehicleImg="/img/map/marker/arrow.png"
                            stopImg="/img/map/marker/point.png"
                            lat={parseFloat(item.markerInfo.latest_lat)}
                            lng={parseFloat(item.markerInfo.latest_lng)}
                            engine={item.markerInfo.engine}
                            speed={item.markerInfo.velocity}
                            vehicleStatus={item.markerInfo.vehicle_status}
                            directionDegree={item.markerInfo.directionDegree}
                          />
                        );
                      }
                      return (
                        <ClusterMarker
                          key={"clusterMarker_" + index}
                          lat={item.cluster_lat}
                          lng={item.cluster_lng}
                          points={item.points}
                        />
                      );
                    })}
                    style={{
                      width: "100%",
                      height: `${window.innerHeight - 45}px`,
                    }}
                  ></HereMap>
                )}

                {this.props.auth.profile.map_type == "google" && (
                  <GoogleMap
                    loadedDateTime={this.props.monitoringState.loadedDateTime}
                    bootstrapURLKeys={{
                      key: this.props.auth.profile.google_map_key,
                      libraries: process.env.REACT_APP_GOOGLE_MAP_USING_LIBRARIES.split(
                        ","
                      ),
                      region: "thailand",
                      language: "th",
                    }}
                    defaultCenter={this.defaultMapValue.center}
                    defaultZoom={this.defaultMapValue.zoom}
                    style={{
                      width: "100%",
                      minHeight: `${window.innerHeight - 45}px`,
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                      this.handleApiLoaded(map, maps)
                    }
                    options={{
                      mapTypeControl: false,
                      fullscreenControl: false,
                      zoomControl: false,
                      maxZoom: 19,
                      streetViewControl: false,
                      styles: [
                        {
                          featureType: "poi.business",
                          stylers: [
                            {
                              visibility: "on",
                            },
                          ],
                        },
                        {
                          featureType: "poi.park",
                          elementType: "labels.text",
                          stylers: [
                            {
                              visibility: "on",
                            },
                          ],
                        },
                      ],
                    }}
                    onChange={this.handleMapChange}
                  >
                    {!JSON.parse(
                      JSON.parse(localStorage.getItem("profile")).config
                    ).menu.tracking.is_group_marker &&
                      this.state.clusters.map((item, index) => {
                        if (item.numPoints === 1) {
                          let result = this.getVehicleMarkerTitle(
                            item.markerInfo
                          );
                          return (
                            <VehicleMarker
                              key={"vehicleMarker" + index}
                              title={result.title}
                              checkLengthTitle={result.check_length}
                              vehicleImg="/img/map/marker/arrow.png"
                              stopImg="/img/map/marker/point.png"
                              lat={parseFloat(item.markerInfo.latest_lat)}
                              lng={parseFloat(item.markerInfo.latest_lng)}
                              engine={item.markerInfo.engine}
                              speed={item.markerInfo.velocity}
                              vehicleStatus={item.markerInfo.vehicle_status}
                              directionDegree={item.markerInfo.directionDegree}
                              onClick={(e) => {
                                this.openInfoPanel(
                                  trackingList,
                                  item.markerInfo.vehicle_id
                                );
                              }}
                            />
                          );
                        }
                        return (
                          <ClusterMarker
                            key={"clusterMarker_" + index}
                            lat={item.cluster_lat}
                            lng={item.cluster_lng}
                            points={item.points}
                          />
                        );
                      })}

                    {JSON.parse(
                      JSON.parse(localStorage.getItem("profile")).config
                    ).menu.tracking.is_group_marker &&
                      this.props.monitoringState.trackingList.length > 0
                      ? this.props.monitoringState.trackingList.map(
                        (item, index) => {
                          let result = this.getVehicleMarkerTitle(item);
                          return (
                            <VehicleMarker
                              key={"vehicleMarker" + index}
                              title={result.title}
                              checkLengthTitle={result.check_length}
                              vehicleImg="/img/map/marker/arrow.png"
                              stopImg="/img/map/marker/point.png"
                              lat={parseFloat(item.latest_lat)}
                              lng={parseFloat(item.latest_lng)}
                              engine={item.engine}
                              speed={item.velocity}
                              vehicleStatus={item.vehicle_status}
                              directionDegree={item.directionDegree}
                              onClick={(e) => {
                                this.openInfoPanel(
                                  trackingList,
                                  item.vehicle_id
                                );
                              }}
                            />
                          );
                        }
                      )
                      : null}
                    {this.state.showLocationLayer &&
                      this.state.locationInfoMarkerLists.map((value) => value)}

                    {this.state.placeMarker &&
                      this.state.placeMarker.map((value) => value)}
                  </GoogleMap>
                )}
                {this.state.tablePanelClose ? (
                  <>
                    <Row
                      justify="center"
                      style={{
                        position: "absolute",
                        margin: "0px 0px 15px 0px",
                        bottom: "0",
                        right: "46.4%",
                        opacity: "0.7",
                      }}
                    >
                      <Col span={24} align="center">
                        <h5>
                          <mark>
                            <LangContext.Consumer>
                              {(i18n) => i18n.u.updatedAt}
                            </LangContext.Consumer>{" "}
                            : {this.state.curTime}
                          </mark>
                        </h5>
                      </Col>
                    </Row>
                    <Row
                      justify="center"
                      style={{
                        position: "absolute",
                        margin: "0px 0px 15px 0px",
                        bottom: "35px",
                        right: "46%",
                      }}
                    >
                      <Col span={24} align="center">
                        {this.state.btnTruckList ? (
                          <Button
                            style={{ borderRadius: 50 }}
                            onClick={() => {
                              this.getVehicleFromBound();
                            }}
                            type="primary"
                          >
                            <LangContext.Consumer>
                              {(i18n) => i18n.f.findCarThisArea}
                            </LangContext.Consumer>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row
                      justify="center"
                      style={{
                        position: "absolute",
                        margin: "0px 0px 15px 0px",
                        bottom: "0",
                        right: "44.5%",
                        opacity: "0.7",
                      }}
                    >
                      <Col span={24} align="center">
                        <h5>
                          <mark>
                            <LangContext.Consumer>
                              {(i18n) => i18n.u.updatedAt}
                            </LangContext.Consumer>{" "}
                            : {this.state.curTime}
                          </mark>
                        </h5>
                      </Col>
                    </Row>
                    <Row
                      justify="center"
                      style={{
                        position: "absolute",
                        margin: "0px 0px 15px 0px",
                        bottom: "35px",
                        right: "44%",
                      }}
                    >
                      <Col span={24} align="center">
                        {this.state.btnTruckList ? (
                          <Button
                            style={{ borderRadius: 50 }}
                            onClick={() => {
                              this.getVehicleFromBound();
                            }}
                            type="primary"
                          >
                            <LangContext.Consumer>
                              {(i18n) => i18n.f.findCarThisArea}
                            </LangContext.Consumer>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Spin>
        </TrackingStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({
    monitoringState,
    auth,
    auth: { profile },
    videoMonitoring,
    honeytoaststream,
  }) => ({
    monitoringState,
    auth,
    profile,
    videoMonitoring,
    honeytoaststream,
  }),
  {
    loadTrackingInitData: loadTrackingInitData.request,
    loadTracking: loadTracking.request,
    loadVehicleTypeMasterData: loadVehicleTypeMasterData.request,
    loadVehicleGroupMasterData: loadVehicleGroupMasterData.request,
    removeLists,
    changeConfigTrackingColumns,
    changeConfigTrackingTableCarRegisColumns,
    changeConfigTrackingTableDriverColumns,
    loadListVehicleMdvr: loadListVehicleMdvr.request,
    loadVehicle: loadVehicle.request,
    loadListVehicleHoneytoast: loadListVehicleHoneytoast.request,
  }
)(Index);
